import type { AxiosError } from 'axios';
import axios from 'axios';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { APIStatus } from '../../../enum';
import type { ErrorMessage } from '../../../models/error';
import type { AsyncThunkConfig } from '../../../models/slice';
import type { FilteredInfo, RequestParams, TableChange } from '../../../models/table';
import { SortedInfo } from '../../../models/table';
import { RaygunErrorHandlerService } from '../../../service/raygun.service';
import { removeEmpty } from '../../../service/table.service';

const { logError } = RaygunErrorHandlerService();

export class AdminJournal {
  id: string | null = null;
  organization_ids: string[] | null = null;
  organizations: string[] | null = null;
  name: string | null = null;
  client_facing_name_alias: string[] | null = null;
  short_name: string | null = null;
  internal_name: string | null = null;
  status_yellow: number | null = null;
  status_red: number | null = null;
  jump_red: number | null = null;
  jump_yellow: number | null = null;
  total_count: number = 0;
}

type AdminJournalSliceType = {
  adminJournalData: AdminJournal[];
  requestAdminJournalParam: RequestParams<AdminJournal>;
  adminApiStatus: APIStatus;
  adminAPIErrorText: string;
  adminJournal: AdminJournal;
};

const initialState: AdminJournalSliceType = {
  adminJournalData: [],
  requestAdminJournalParam: {
    pagination: {
      current: 1,
      pageSize: 50,
      defaultPageSize: 50,
    },
    sortedInfo: new SortedInfo<AdminJournal>('name', 'ascend'),
    filteredInfo: {},
  },
  adminApiStatus: APIStatus.IDLE,
  adminAPIErrorText: '',
  adminJournal: new AdminJournal(),
};

export const fetchAdminJournals = createAsyncThunk<AdminJournal[], undefined, AsyncThunkConfig>(
  'adminJournal/fetchAdminJournals',
  async (_, thunkAPI) => {
    try {
      const { adminJournalSlice } = thunkAPI.getState();
      const response = (await axios.post(
        'v3_get_all_product_admin',
        adminJournalSlice.requestAdminJournalParam,
      )) as AdminJournal[];
      return response ?? [];
    } catch (e) {
      logError(e, ['adminJournalSlice', 'fetchAdminJournals']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const adminJournalSlice = createSlice({
  name: 'adminJournal',
  initialState,
  reducers: {
    handleJournalTableChange: (state, action: PayloadAction<TableChange<AdminJournal>>) => {
      const filters = action.payload.filters as FilteredInfo;
      const sorter = action.payload.sorter as SortedInfo<AdminJournal>;
      if (!sorter.order) {
        sorter.order = null;
      }
      state.requestAdminJournalParam.pagination = action.payload.pagination;
      state.requestAdminJournalParam.sortedInfo = sorter;
      state.requestAdminJournalParam.filteredInfo = removeEmpty(filters);
    },
    clearFilter: (state) => {
      state.requestAdminJournalParam = initialState.requestAdminJournalParam;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdminJournals.pending, (state, _action) => {
        state.adminApiStatus = APIStatus.PENDING;
      })
      .addCase(fetchAdminJournals.fulfilled, (state, action) => {
        state.adminJournalData = action.payload;
        state.adminApiStatus = APIStatus.FULFILLED;
      })
      .addCase(fetchAdminJournals.rejected, (state, action) => {
        state.adminApiStatus = APIStatus.ERROR;
        state.adminAPIErrorText = (action.payload as ErrorMessage)?.displayText;
      });
  },
});

export const { handleJournalTableChange, clearFilter } = adminJournalSlice.actions;
