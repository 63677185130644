import type { LabeledValue } from 'antd/lib/select';
import type { ColumnsType } from 'antd/lib/table';
import type { Dayjs } from 'dayjs';

import type { ClinicianSite } from '../admin-clinicians/adminClinicianSlice';
import type { AdminSite } from '../admin-sites/adminSitesSlice';
import type {
  Intervention,
  InterventionValue,
  MostCommonWordCount,
  NameTotal,
  NameValue,
} from './programDashboardSlice';
import { BarLineData, InterventionType } from './programDashboardSlice';

export const dateTimeFilterFormat = 'YYYY-MM-DD HH:mm:ss';

export const datesRange: LabeledValue[] = [
  {
    label: '1 Month',
    value: '1 months',
  },
  {
    label: '2 Months',
    value: '2 months',
  },
  {
    label: '3 Months',
    value: '3 months',
  },
];

export const wordsColumns: ColumnsType<MostCommonWordCount> = [
  {
    title: 'Word',
    dataIndex: 'word',
    key: 'word',
    align: 'center',
  },
  {
    title: 'Times used',
    dataIndex: 'frequency',
    key: 'frequency',
    align: 'center',
  },
];

export const interventionColumns: ColumnsType<NameValue> = [
  {
    title: 'Intervention',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'value',
    dataIndex: 'value',
    key: 'value',
  },
];

export const getFiltersInfo = (
  startDate: Dayjs | undefined,
  endDate: Dayjs | undefined,
  siteId: string | undefined,
  adminSites: AdminSite[],
  clinicianSites: ClinicianSite[],
) => {
  return (
    startDate &&
    endDate &&
    '(' +
      startDate.format('YYYY-MM-DD') +
      ' - ' +
      endDate.format('YYYY-MM-DD') +
      (siteId
        ? clinicianSites?.length > 0
          ? `, ${clinicianSites.find((s) => s.site_id === siteId)?.name}`
          : `, ${adminSites.find((s) => s.id === siteId)?.name}`
        : '') +
      ')'
  );
};

export const getPercetangeBarData = (totals: NameTotal[]): [BarLineData, BarLineData] => {
  const total = totals.reduce((acc, g) => acc + g.total, 0);
  const percents = totals.map((g) => Math.round((g.total / total) * 1000) / 10);
  const labels = totals.map(({ age_group, primary_diagnosis, gender }) => age_group || primary_diagnosis || gender);
  return [new BarLineData('Percentage', 'bar', percents), new BarLineData(null, 'category', labels)];
};

export const compareDates = (a: Dayjs, b: Dayjs) => {
  if (a.isAfter(b)) {
    return 1;
  } else if (a.isSame(b)) {
    return 0;
  } else {
    return -1;
  }
};

export const getPercentage = (value: number | undefined, total: number) => {
  const rate = (value || 0) / total;

  if (isNaN(rate)) {
    return 0;
  } else {
    return Math.round(rate * 1000) / 10;
  }
};

export const getInterventionsLogged = (interventions: Intervention[]) => {
  let sum = 0;
  const map = interventions.reduce((acc, { intervention_type }) => {
    acc.set(intervention_type, (acc.get(intervention_type) || 0) + 1);
    sum += 1;
    return acc;
  }, new Map<InterventionValue, number>());
  return [
    sum,
    [
      { name: InterventionType.HighSeverity, value: map.get(InterventionType.HighSeverity) || 0 },
      { name: InterventionType.MediumSeverity, value: map.get(InterventionType.MediumSeverity) || 0 },
      { name: InterventionType.LowSeverity, value: map.get(InterventionType.LowSeverity) || 0 },
      { name: InterventionType.FeedbackOnly, value: map.get(InterventionType.FeedbackOnly) || 0 },
    ],
  ] as const;
};
