import { notification } from 'antd';
import type { AxiosError } from 'axios';
import axios from 'axios';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { APIStatus } from '../../../enum';
import type { ErrorMessage } from '../../../models/error';
import type { AsyncThunkConfig } from '../../../models/slice';
import type { FilteredInfo, RequestParams, TableChange } from '../../../models/table';
import { SortedInfo } from '../../../models/table';
import { RaygunErrorHandlerService } from '../../../service/raygun.service';
import { removeEmpty } from '../../../service/table.service';

const { logError } = RaygunErrorHandlerService();

export type ScheduledProductVM = {
  id: string;
  available_date: string;
  client_name: string;
  product_id: string;
  product: string;
  total_count: number;
  due_date: string;
  client_id: string;
  timezone_name: string;
};

type ScheduledProductsSliceType = {
  scheduledProducts: ScheduledProductVM[];
  requestParams: RequestParams<ScheduledProductVM>;
  scheduledProductApiStatus: APIStatus;
  scheduledProductAPIErrorText: string;
  missedScheduledProductToggle: boolean;
  missedScheduledProductCount: number;
};

const initialState: ScheduledProductsSliceType = {
  scheduledProducts: [],
  requestParams: {
    pagination: {
      current: 1,
      pageSize: 50,
      defaultPageSize: 50,
    },
    sortedInfo: new SortedInfo<ScheduledProductVM>('available_date', 'ascend'),
    filteredInfo: {},
  },
  scheduledProductApiStatus: APIStatus.IDLE,
  missedScheduledProductToggle: true,
  scheduledProductAPIErrorText: '',
  missedScheduledProductCount: 0,
};

export const fetchScheduledProducts = createAsyncThunk<ScheduledProductVM[], undefined, AsyncThunkConfig>(
  'scheduledProducts/fetchScheduledProducts',
  async (_, thunkAPI) => {
    try {
      const { scheduledProductsSlice } = thunkAPI.getState();
      const endpoint = scheduledProductsSlice.missedScheduledProductToggle
        ? 'v3_get_missed_scheduled_product'
        : 'v3_get_upcoming_scheduled_product';
      const response = await axios.post(endpoint, scheduledProductsSlice.requestParams);
      return (response as unknown as ScheduledProductVM[]) ?? [];
    } catch (e) {
      logError(e, ['scheduledProductsSlice', 'fetchScheduledProducts']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const dismissMissedScheduledProduct = createAsyncThunk<ScheduledProductVM[], string, AsyncThunkConfig>(
  'scheduledProducts/dismissMissedScheduledProduct',
  async (scheduled_product_id: string, thunkAPI) => {
    try {
      const response = await axios.post('v3_dismiss_missed_scheduled_product', {
        missed_scheduled_product_id: scheduled_product_id,
      });
      if (response) {
        thunkAPI.dispatch(fetchScheduledProducts());
        notification.destroy();
        notification.success({
          message: 'Dismissed successfully',
          description: 'Success',
        });
        return (response as unknown as ScheduledProductVM[]) ?? [];
      } else {
        return [];
      }
    } catch (e) {
      logError(e, ['scheduledProductsSlice', 'dismissMissedScheduledProduct']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const scheduledProductsSlice = createSlice({
  name: 'scheduledProducts',
  initialState,
  reducers: {
    handleTableChanges: (state, action: PayloadAction<TableChange<ScheduledProductVM>>) => {
      const filters = action.payload.filters as FilteredInfo;
      const sorter = action.payload.sorter as SortedInfo<ScheduledProductVM>;
      if (!sorter.order) {
        sorter.order = null;
      }
      state.requestParams.pagination = action.payload.pagination;
      state.requestParams.sortedInfo = sorter;
      state.requestParams.filteredInfo = removeEmpty(filters);
    },
    clearFilter: (state) => {
      state.requestParams.pagination = initialState.requestParams.pagination;
      state.requestParams.sortedInfo = initialState.requestParams.sortedInfo;
      state.requestParams.filteredInfo = initialState.requestParams.filteredInfo;
    },

    setScheduledProductsToggle: (state, action) => {
      state.missedScheduledProductToggle = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchScheduledProducts.pending, (state, _action) => {
        state.scheduledProductApiStatus = APIStatus.PENDING;
      })
      .addCase(fetchScheduledProducts.fulfilled, (state, action) => {
        state.scheduledProducts = action.payload;
        if (state.missedScheduledProductToggle) {
          state.missedScheduledProductCount = action.payload[0]?.total_count;
        }
        state.scheduledProductApiStatus = APIStatus.FULFILLED;
      })
      .addCase(fetchScheduledProducts.rejected, (state, action) => {
        state.scheduledProductApiStatus = APIStatus.ERROR;
        state.scheduledProductAPIErrorText = (action.payload as ErrorMessage)?.displayText ?? '';
      });
  },
});

export const { handleTableChanges, clearFilter, setScheduledProductsToggle } = scheduledProductsSlice.actions;
