import { notification } from 'antd';

import { UserDiagnosis } from '../../../enum';
import type { AdminOrganization } from './adminOrganizationSlice';

const validateFields = (adminOrganization: AdminOrganization) => {
  const missingFields = [];
  if (!adminOrganization.name) missingFields.push('Name');
  if (!adminOrganization.primary_domain) missingFields.push('Domain name');
  if (!adminOrganization.country) missingFields.push('Country');
  if (!adminOrganization.product_ids || adminOrganization.product_ids.length < 1) missingFields.push('Products');
  if (!adminOrganization.language_codes || adminOrganization.language_codes.length < 1) missingFields.push('Languages');
  if (!adminOrganization.default_language) missingFields.push('Default language');
  if (!adminOrganization.report_ids || adminOrganization.report_ids.length < 1) missingFields.push('Reports');
  if (!adminOrganization.resource_ids || adminOrganization.resource_ids.length < 1) missingFields.push('Resources');
  if (!adminOrganization.diagnosis_ids || adminOrganization.diagnosis_ids.length < 2) missingFields.push('Diagnoses');
  if (!adminOrganization.clinician_job_ids || adminOrganization.clinician_job_ids.length < 1)
    missingFields.push('Clinician jobs');
  return missingFields;
};

export const validateAdminOrganization = (adminOrganization: AdminOrganization) => {
  const missingFields = validateFields(adminOrganization);
  if (missingFields.length > 0) {
    notification.error({
      message: 'Missing required fields',
      description: `Please provide values for all required fields: ${missingFields.join(', ')}`,
    });
    return false;
  } else if (adminOrganization.use_mood && (!adminOrganization.mood_ids || adminOrganization.mood_ids.length < 1)) {
    notification.error({
      message: 'Missing moods',
      description: 'Please select some moods.',
    });
    return false;
  } else if (adminOrganization.use_badge && (!adminOrganization.badge_ids || adminOrganization.badge_ids.length < 1)) {
    notification.error({
      message: 'Missing badges',
      description: 'Please select some badges.',
    });
    return false;
  } else if (adminOrganization.email && !adminOrganization.email.match(/^[^@]+@[^@]+\.\w\w+$/g)) {
    notification.error({
      message: 'Invalid email',
      description: 'Please provide a valid email.',
    });
    return false;
  } else if (
    adminOrganization.inactive_client_organization_limit &&
    (adminOrganization.inactive_client_organization_limit > 500 ||
      adminOrganization.inactive_client_organization_limit < 60)
  ) {
    notification.error({
      message: 'Invalid inactive client limit',
      description: 'Please provide inactive client limit between 60 to 500.',
    });
  } else {
    return true;
  }
};

export const keepOtherDiagnosisLast = (selectedDiagnosis: string[] = []) => {
  const diagnosisIds = [...selectedDiagnosis];
  const otherIndex = diagnosisIds.findIndex((id) => id === UserDiagnosis.OTHER);
  if (otherIndex > -1) {
    diagnosisIds.splice(otherIndex, 1);
  }
  diagnosisIds.push(UserDiagnosis.OTHER);
  return diagnosisIds;
};
