import { notification } from 'antd';
import type { LoaderFunctionArgs } from 'react-router';
import { redirect } from 'react-router';

import type { createStore } from '../../../store';
import { checkToken, updateTokenFromPath } from './resetPasswordSlice';

export const resetPasswordLoaderWithStore = (store: ReturnType<typeof createStore>) => {
  const resetPasswordLoader = async ({ params }: LoaderFunctionArgs) => {
    if (params.token) {
      store.dispatch(updateTokenFromPath(params.token));
      const response = await store.dispatch(checkToken());

      if (response.meta.requestStatus === 'fulfilled' && response.payload === false) {
        notification.error({
          message: 'Token expired',
          description: 'Your link is expired. Please request a new password reset email.',
        });
        return redirect('/login');
      } else if (response.meta.requestStatus === 'rejected') {
        notification.error({
          message: 'Error',
          description: 'Invalid token. Please request a new password reset email.',
        });
        return redirect('/forgot-password');
      }
    }
    return null;
  };

  return resetPasswordLoader;
};
