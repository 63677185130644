import { useEffect } from 'react';
import { usePostHog } from 'posthog-js/react';

import { environment } from '../environment/environment';
import { useAppSelector } from '../hooks';

const useInitApp = () => {
  const posthog = usePostHog();
  const currentUser = useAppSelector((state) => state.authSlice.currentUser);

  useEffect(() => {
    if (currentUser?.user_id) {
      posthog?.identify(currentUser.user_id, {
        user_id: currentUser.user_id,
        organization_id: currentUser.organization_id,
        role: currentUser.role,
        region: currentUser.region === 'us-prod' ? 'US' : 'CA',
        version: environment.versionNumber,
      });
    } else {
      posthog?.reset();
    }
  }, [currentUser?.organization_id, currentUser?.region, currentUser?.user_id, currentUser.role, posthog]);
};

export default useInitApp;
