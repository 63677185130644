import { notification } from 'antd';
import type { AxiosError } from 'axios';
import axios from 'axios';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { APIStatus } from '../../../enum';
import type { ErrorMessage } from '../../../models/error';
import type { AsyncThunkConfig } from '../../../models/slice';
import type { FilteredInfo, RequestParams, TableChange } from '../../../models/table';
import { SortedInfo } from '../../../models/table';
import { RaygunErrorHandlerService } from '../../../service/raygun.service';
import { removeEmpty } from '../../../service/table.service';
import { validateAdminOrganization } from './admin-organization.helper';

const { logError } = RaygunErrorHandlerService();

export type Country = 'Canada' | 'United States';

export class AdminOrganization {
  address: string | null = null;
  city: string | null = null;
  email: string | null = null;
  phone: string | null = null;
  postal_code: string | null = null;
  province: string | null = null;
  id: string | null = null;
  deleted: boolean | null = null;
  created: string | null = null;
  locked: boolean | null = null;
  licensed_entities: number | null = null;
  primary_domain: string | null = null;
  clinician_job_ids: string[] | null = null;
  diagnosis_ids: string[] | null = null;
  mood_ids: string[] | null = null;
  badge_ids: string[] | null = null;
  resource_ids: string[] | null = null;
  report_ids: string[] | null = null;
  product_ids: string[] | null = null;
  language_codes: string[] | null = null;
  default_language: string | null = null;
  validation_alert_lifespan: number | null = null;
  validation_event_lifespan: number | null = null;
  validation_event_auto: boolean | null = null;
  name: string | null = null;
  use_sentiment: boolean = true;
  use_location: boolean = true;
  use_sms: boolean = true;
  use_si: boolean = false;
  country: Country = 'Canada';
  internal: boolean | null = null;
  use_actions: boolean = true;
  use_badge: boolean = true;
  use_client_journal_selection: boolean = false;
  use_mood: boolean = true;
  use_multi_product_clients: boolean = false;
  use_streak_component: boolean = true;
  use_streak_component_title: boolean = true;
  use_text_entry: boolean = true;
  use_bam_17_trend_graph: boolean = false;
  use_phq_9_trend_graph: boolean = false;
  use_cravings_quick_checkin: boolean = false;
  use_indicator_graph_card: boolean = true;
  use_risk_calendar_graph: boolean = true;
  use_status_time_graph: boolean = true;
  use_risk_indicator_graph: boolean = true;
  use_score_column: boolean = false;
  use_insurance_carrier: boolean = false;
  use_apa_features: boolean = false;
  use_risk_nomenclature: boolean = true;
  use_callback: boolean = false;
  use_client_program: boolean = false;
  use_client_type: boolean = false;
  use_clinician_dashboard: boolean = false;
  use_always_available_products: boolean = true;
  use_scheduler: boolean = false;
  total_count: number = 0;
  inactive_client_organization_limit: number | null = null;
  use_qr_code: boolean = false;
}

type AdminProduct = {
  assigned: boolean;
  product_id: string;
  short_name: string;
  internal_name: string;
  name: string;
};

type AdminLanguage = {
  language_code: string;
  language_name: string;
};

type AdminReport = {
  description: string;
  endpoint: string;
  format: string;
  id: string;
  name: string;
  show_age_filter: boolean;
  show_date_range_selecter: boolean;
  show_diagnoses_filter: boolean;
  show_gender_filter: boolean;
  show_site_filter: boolean;
  show_start_end_date_filter: boolean;
};

type AdminResource = {
  active: boolean;
  id: string;
  name: string;
  copy: string;
  alias: string;
  language_code: string;
  type: string;
  order: number;
};

type AdminBadge = {
  id: string;
  name: string;
  description: string;
  alias: string;
  icon: string;
  default_order: number;
};

type AdminMood = {
  id: string;
  mood_name: string;
  name_alias: string;
  default_order: number;
};

type AdminDiagnose = {
  id: string;
  name: string;
};

type AdminClinicianJob = {
  id: string;
  name: string;
  default_order: number;
};

type AdminOrganizationSliceType = {
  adminOrganizationsData: AdminOrganization[];
  clinicianJobs: AdminClinicianJob[];
  badges: AdminBadge[];
  diagnoses: AdminDiagnose[];
  moods: AdminMood[];
  resources: AdminResource[];
  languages: AdminLanguage[];
  products: AdminProduct[];
  reports: AdminReport[];
  requestAdminOrganizationsParam: RequestParams<AdminOrganization>;
  upsertAdminOrgApiStatus: APIStatus;
  showModal: string;
  adminOrganization: AdminOrganization;
  selectedAdminOrganization: AdminOrganization | null;
};

export enum ADMIN_ORG_MODAL {
  PASSWORD_RESET = 'password_reset',
  IDLE = 'idle',
  EDIT_ORGANIZATION = 'edit_organization',
}

const initialState: AdminOrganizationSliceType = {
  adminOrganizationsData: [],
  clinicianJobs: [],
  diagnoses: [],
  moods: [],
  badges: [],
  resources: [],
  languages: [],
  products: [],
  reports: [],
  requestAdminOrganizationsParam: {
    pagination: {
      current: 1,
      pageSize: 50,
      defaultPageSize: 50,
    },
    sortedInfo: new SortedInfo<AdminOrganization>('name', 'ascend'),
    filteredInfo: {},
  },
  upsertAdminOrgApiStatus: APIStatus.IDLE,
  showModal: ADMIN_ORG_MODAL.IDLE,
  adminOrganization: new AdminOrganization(),
  selectedAdminOrganization: null,
};

export const fetchAdminOrganizations = createAsyncThunk<AdminOrganization[], undefined, AsyncThunkConfig>(
  'adminOrganization/fetchAdminOrganizations',
  async (_, thunkAPI) => {
    try {
      const { adminOrganizationSlice } = thunkAPI.getState();
      const response = (await axios.post(
        'v2_organization',
        adminOrganizationSlice.requestAdminOrganizationsParam,
      )) as AdminOrganization[];
      return response ?? [];
    } catch (e) {
      logError(e, ['adminOrganizationSlice', 'fetchAdminOrganizations']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const fetchSelectedAdminOrganization = createAsyncThunk<AdminOrganization | null, string, AsyncThunkConfig>(
  'adminOrganization/fetchSelectedAdminOrganization',
  async (organization_id, thunkAPI) => {
    try {
      const response = (await axios.post('v2_organization', {
        filteredInfo: { id: [organization_id] },
      })) as AdminOrganization[];
      return response?.[0] ?? null;
    } catch (e) {
      logError(e, ['adminOrganizationSlice', 'fetchSelectedAdminOrganization']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const fetchAllProducts = createAsyncThunk<AdminProduct[], undefined, AsyncThunkConfig>(
  'adminOrganization/fetchAllProducts',
  async (_, thunkAPI) => {
    try {
      const response = (await axios.get('v3_get_all_product')) as AdminProduct[];
      return response ?? [];
    } catch (e) {
      logError(e, ['adminOrganizationSlice', 'fetchAllProducts']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const fetchAllLanguages = createAsyncThunk<AdminLanguage[], undefined, AsyncThunkConfig>(
  'adminOrganization/fetchAllLanguages',
  async (_, thunkAPI) => {
    try {
      const response = (await axios.get('v2_organization_language')) as AdminLanguage[];
      return response ?? [];
    } catch (e) {
      logError(e, ['adminOrganizationSlice', 'fetchAllLanguages']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const fetchOrganizationReports = createAsyncThunk<AdminReport[], undefined, AsyncThunkConfig>(
  'adminOrganization/fetchOrganizationReports',
  async (_, thunkAPI) => {
    try {
      const response = (await axios.get('v2_organization_report')) as AdminReport[];
      return response ?? [];
    } catch (e) {
      logError(e, ['adminOrganizationSlice', 'fetchOrganizationReports']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const fetchAllResources = createAsyncThunk<AdminResource[], undefined, AsyncThunkConfig>(
  'adminOrganization/fetchAllResources',
  async (_, thunkAPI) => {
    try {
      const response = (await axios.post('v3_resource')) as AdminResource[];

      return response ?? [];
    } catch (e) {
      logError(e, ['adminOrganizationSlice', 'fetchAllResources']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const fetchAllBadges = createAsyncThunk<AdminBadge[], undefined, AsyncThunkConfig>(
  'adminOrganization/fetchAllBadges',
  async (_, thunkAPI) => {
    try {
      const response = (await axios.get('v2_organization_badge')) as AdminBadge[];
      return response ?? [];
    } catch (e) {
      logError(e, ['adminOrganizationSlice', 'fetchAllBadges']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const fetchAllMoods = createAsyncThunk<AdminMood[], undefined, AsyncThunkConfig>(
  'adminOrganization/fetchAllMoods',
  async (_, thunkAPI) => {
    try {
      const response = (await axios.get('v2_organization_mood')) as AdminMood[];
      return response ?? [];
    } catch (e) {
      logError(e, ['adminOrganizationSlice', 'fetchAllMoods']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const fetchAllDiagnoses = createAsyncThunk<AdminDiagnose[], undefined, AsyncThunkConfig>(
  'adminOrganizations/fetchAllDiagnoses',
  async (_, thunkAPI) => {
    try {
      const response = (await axios.get('v2_organization_diagnosis')) as AdminDiagnose[];
      return response ?? [];
    } catch (e) {
      logError(e, ['adminOrganizationSlice', 'fetchAllDiagnoses']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const fetchAllClinicianJobs = createAsyncThunk<AdminClinicianJob[], undefined, AsyncThunkConfig>(
  'adminOrganization/fetchAllClinicianJobs',
  async (_, thunkAPI) => {
    try {
      const response = (await axios.get('v2_organization_job')) as AdminClinicianJob[];
      return response ?? [];
    } catch (e) {
      logError(e, ['adminOrganizationSlice', 'fetchAllClinicianJobs']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

type SetInternal = {
  organization_id: string;
  internal: boolean;
};

export const adminOrganizationSetInternal = createAsyncThunk<string, SetInternal, AsyncThunkConfig>(
  'adminOrganization/adminOrganizationSetInternal',
  async (setInternal, thunkAPI) => {
    try {
      const response = (await axios.post('v2_organization_internal_set', setInternal)) as string;
      if (response) {
        thunkAPI.dispatch(fetchAdminOrganizations());
      }
      return response; // organization_id
    } catch (e) {
      logError(e, ['adminOrganizationSlice', 'adminOrganizationSetInternal']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const deleteOrganization = createAsyncThunk<string, string, AsyncThunkConfig>(
  'adminOrganization/deleteOrganization',
  async (organization_id, thunkAPI) => {
    try {
      const response = (await axios.post('v2_organization_delete', { organization_id })) as string;
      if (response) {
        thunkAPI.dispatch(fetchAdminOrganizations());
        notification.destroy();
        notification.success({
          message: 'Organization successfully deleted',
          description: 'Success',
        });
      } else {
        notification.destroy();
        notification.error({
          message: 'Something went wrong',
          description: '...',
        });
      }
      return response; // organization_id
    } catch (e) {
      logError(e, ['adminOrganizationSlice', 'deleteOrganization']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

type LockOrganization = {
  id: string;
  locked: boolean;
};

export const lockOrganization = createAsyncThunk<string, LockOrganization, AsyncThunkConfig>(
  'adminOrganization/lockOrganization',
  async (lockData, thunkAPI) => {
    try {
      const response = (await axios.post('/v2_admin_organization_locked_set', lockData)) as string;
      if (response) {
        thunkAPI.dispatch(fetchAdminOrganizations());
        notification.destroy();
        notification.success({
          message: 'Success',
          description: `Your organization has been successfully ${lockData.locked ? '' : 'un'}locked.`,
        });
      }
      return response; // organization_id
    } catch (e) {
      logError(e, ['adminOrganizationSlice', 'lockOrganization']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const upsertAdminOrganization = createAsyncThunk<string, AdminOrganization, AsyncThunkConfig>(
  'adminOrganization/upsertAdminOrganization',
  async (upsertAdminOrganization, thunkAPI) => {
    try {
      if (validateAdminOrganization(upsertAdminOrganization)) {
        const response = (await axios.post('v2_organization_upsert', upsertAdminOrganization)) as string;
        if (response) {
          thunkAPI.dispatch(closeEditOrganizationModal());
          thunkAPI.dispatch(fetchAdminOrganizations());
          notification.destroy();
          notification.success({
            message: 'Organization updated successfully',
            description: 'Success',
          });
        } else {
          notification.destroy();
          notification.error({
            message: 'Something went wrong',
            description: '...',
          });
        }
        return response; // organization_id
      }
      return '';
    } catch (e) {
      logError(e, ['adminOrganizationSlice', 'upsertAdminOrganization']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const adminOrganizationSlice = createSlice({
  name: 'adminOrganization',
  initialState,
  reducers: {
    handleOrganizationsTableChange: (state, action: PayloadAction<TableChange<AdminOrganization>>) => {
      const filters = action.payload.filters as FilteredInfo;
      const sorter = action.payload.sorter as SortedInfo<AdminOrganization>;
      if (!sorter.order) {
        sorter.order = null;
      }
      state.requestAdminOrganizationsParam.pagination = action.payload.pagination;
      state.requestAdminOrganizationsParam.sortedInfo = sorter;
      state.requestAdminOrganizationsParam.filteredInfo = removeEmpty(filters);
    },
    clearFilter: (state) => {
      state.requestAdminOrganizationsParam = initialState.requestAdminOrganizationsParam;
    },
    showEditOrganizationModal: (state, action: PayloadAction<ADMIN_ORG_MODAL>) => {
      state.showModal = action.payload;
    },
    setAdminOrganization: (state, action: PayloadAction<AdminOrganization>) => {
      state.adminOrganization = action.payload;
    },
    closeEditOrganizationModal: (state) => {
      state.showModal = ADMIN_ORG_MODAL.IDLE;
    },
    resetSelectedAdminOrganization: (state) => {
      state.selectedAdminOrganization = new AdminOrganization();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(upsertAdminOrganization.pending, (state, _action) => {
        state.upsertAdminOrgApiStatus = APIStatus.PENDING;
      })
      .addCase(upsertAdminOrganization.rejected, (state, _action) => {
        state.upsertAdminOrgApiStatus = APIStatus.ERROR;
      })
      .addCase(upsertAdminOrganization.fulfilled, (state, _action) => {
        state.upsertAdminOrgApiStatus = APIStatus.FULFILLED;
      })
      .addCase(fetchAdminOrganizations.fulfilled, (state, action) => {
        state.adminOrganizationsData = action.payload;
      })
      .addCase(fetchAllProducts.fulfilled, (state, action) => {
        state.products = action.payload;
      })
      .addCase(fetchAllLanguages.fulfilled, (state, action) => {
        state.languages = action.payload;
      })
      .addCase(fetchOrganizationReports.fulfilled, (state, action) => {
        state.reports = action.payload;
      })
      .addCase(fetchAllResources.fulfilled, (state, action) => {
        state.resources = action.payload;
      })
      .addCase(fetchAllBadges.fulfilled, (state, action) => {
        state.badges = action.payload;
      })
      .addCase(fetchAllMoods.fulfilled, (state, action) => {
        state.moods = action.payload;
      })
      .addCase(fetchAllDiagnoses.fulfilled, (state, action) => {
        state.diagnoses = action.payload;
      })
      .addCase(fetchAllClinicianJobs.fulfilled, (state, action) => {
        state.clinicianJobs = action.payload;
      })
      .addCase(fetchSelectedAdminOrganization.fulfilled, (state, action) => {
        state.selectedAdminOrganization = action.payload;
      });
  },
});

export const {
  handleOrganizationsTableChange,
  clearFilter,
  showEditOrganizationModal,
  setAdminOrganization,
  closeEditOrganizationModal,
  resetSelectedAdminOrganization,
} = adminOrganizationSlice.actions;
