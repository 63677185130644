import type { AxiosError } from 'axios';
import axios from 'axios';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { APIStatus } from '../../../../../enum';
import type { ErrorMessage } from '../../../../../models/error';
import type { AsyncThunkConfig } from '../../../../../models/slice';
import type { FilteredInfo, RequestParams, TableChange } from '../../../../../models/table';
import { SortedInfo } from '../../../../../models/table';
import { RaygunErrorHandlerService } from '../../../../../service/raygun.service';
import { removeEmpty } from '../../../../../service/table.service';

const { logError } = RaygunErrorHandlerService();

export type RecentBadges = {
  badge: string;
  icon_url: string;
  badge_id: string;
  description: string;
  count: number;
  last_earned: string;
  total_count: number;
};

type ClientBadgeSliceType = {
  recentBadges: RecentBadges[];
  clientOrganizationBadges: ClientOrganizationBadges[];
  clientBadgeApiStatus: APIStatus;
  requestClientBadgesParams: RequestParams<RecentBadges>;
};

type ClientOrganizationBadges = {
  badge: string;
  badge_id: string;
  description: string;
};

const initialState: ClientBadgeSliceType = {
  recentBadges: [],
  clientOrganizationBadges: [],
  clientBadgeApiStatus: APIStatus.IDLE,
  requestClientBadgesParams: {
    pagination: {
      current: 1,
      pageSize: 50,
      defaultPageSize: 50,
    },
    sortedInfo: new SortedInfo<RecentBadges>('last_earned', 'descend'),
    filteredInfo: {},
  },
};

export const fetchClientBadges = createAsyncThunk<RecentBadges[], string, AsyncThunkConfig>(
  'clientBadge/fetchClientBadges',
  async (client_id, thunkAPI) => {
    try {
      const { pagination, sortedInfo, filteredInfo } = thunkAPI.getState().clientBadgeSlice.requestClientBadgesParams;
      const response = await axios.post('v3_client_profile_badges', {
        client_id,
        pagination,
        sortedInfo,
        filteredInfo,
      });
      return response as unknown as RecentBadges[];
    } catch (e) {
      logError(e, ['clientBadgeSlice', 'fetchClientBadges']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const fetchClientOrganizationBadges = createAsyncThunk<ClientOrganizationBadges[], undefined, AsyncThunkConfig>(
  'clientBadge/fetchClientOrganizationBadges',
  async (_, thunkAPI) => {
    try {
      const response = await axios.get('v3_client_organization_badges');
      return response as unknown as ClientOrganizationBadges[];
    } catch (e) {
      logError(e, ['clientBadgeSlice', 'fetchClientOrganizationBadges']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const clientBadgeSlice = createSlice({
  name: 'clientBadge',
  initialState,
  reducers: {
    handleClientBadgesChange: (state, action: PayloadAction<TableChange<RecentBadges>>) => {
      const filters = action.payload.filters as FilteredInfo;
      const sorter = action.payload.sorter as SortedInfo<RecentBadges>;
      if (!sorter.order) {
        sorter.order = null;
      }
      state.requestClientBadgesParams.pagination = action.payload.pagination;
      state.requestClientBadgesParams.sortedInfo = sorter;
      state.requestClientBadgesParams.filteredInfo = removeEmpty(filters);
    },
    clearFilter: (state) => {
      state.requestClientBadgesParams.pagination = initialState.requestClientBadgesParams.pagination;
      state.requestClientBadgesParams.sortedInfo = initialState.requestClientBadgesParams.sortedInfo;
      state.requestClientBadgesParams.filteredInfo = initialState.requestClientBadgesParams.filteredInfo;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchClientBadges.pending, (state, _action) => {
        state.clientBadgeApiStatus = APIStatus.PENDING;
      })
      .addCase(fetchClientBadges.fulfilled, (state, action) => {
        state.recentBadges = action.payload;
        state.clientBadgeApiStatus = APIStatus.FULFILLED;
      })
      .addCase(fetchClientBadges.rejected, (state, _action) => {
        state.clientBadgeApiStatus = APIStatus.ERROR;
      })
      .addCase(fetchClientOrganizationBadges.pending, (state, _action) => {
        state.clientBadgeApiStatus = APIStatus.PENDING;
      })
      .addCase(fetchClientOrganizationBadges.fulfilled, (state, action) => {
        state.clientOrganizationBadges = action.payload;
        state.clientBadgeApiStatus = APIStatus.FULFILLED;
      })
      .addCase(fetchClientOrganizationBadges.rejected, (state, _action) => {
        state.clientBadgeApiStatus = APIStatus.ERROR;
      });
  },
});

export const { handleClientBadgesChange, clearFilter } = clientBadgeSlice.actions;
