import type { AxiosError } from 'axios';
import axios from 'axios';
import type { ReactNode } from 'react';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { APIStatus } from '../../../enum';
import { Client } from '../../../models/client';
import type { ErrorMessage } from '../../../models/error';
import type { AsyncThunkConfig } from '../../../models/slice';
import type { FilteredInfo, RequestParams, TableChange } from '../../../models/table';
import { SortedInfo } from '../../../models/table';
import { RaygunErrorHandlerService } from '../../../service/raygun.service';
import { removeEmpty } from '../../../service/table.service';
import { sentiment_status } from './dashboard.helper';

const { logError } = RaygunErrorHandlerService();

export class DashboardClient {
  current: number = 0;
  max: number = 0;
  data: Client[] = [];
  label: string = '';
  color: string = '';
  background: string = '';
  icon: ReactNode = null;
  type: 'green' | 'yellow' | 'red' | 'na' | null = null;
}

type DashboardSliceType = {
  clients: Client[];
  requestClientParam: RequestParams<Client>;
  apiStatus: APIStatus;
  apiErrorText: string;
  client: Client;
};

const initialState: DashboardSliceType = {
  clients: [],
  requestClientParam: {
    pagination: {
      current: 1,
      pageSize: 50,
      defaultPageSize: 50,
    },
    sortedInfo: new SortedInfo<Client>('last_check_in', 'descend'),
    filteredInfo: { my_client: ['true'] },
  },
  apiStatus: APIStatus.IDLE,
  apiErrorText: '',
  client: new Client(),
};

export const fetchClients = createAsyncThunk<Client[], undefined, AsyncThunkConfig>(
  'dashboard/fetchClients',
  async (_, thunkAPI) => {
    try {
      const { dashboardSlice } = thunkAPI.getState();
      const response = (await axios.post('v2_client', dashboardSlice.requestClientParam)) as Client[];
      return response ?? [];
    } catch (e) {
      logError(e, ['dashboardSlice', 'fetchClients']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    handleTableChange: (state, action: PayloadAction<TableChange<Client>>) => {
      const filters = action.payload.filters as FilteredInfo;
      const sorter = action.payload.sorter as SortedInfo<Client>;
      if (!sorter.order) {
        sorter.order = null;
      }
      state.requestClientParam.pagination = action.payload.pagination;
      state.requestClientParam.sortedInfo = sorter;
      state.requestClientParam.filteredInfo = removeEmpty({ ...state.requestClientParam.filteredInfo, ...filters });
    },
    handleFiltersChange: (state, action: PayloadAction<FilteredInfo>) => {
      const filters = action.payload;
      state.requestClientParam.filteredInfo = removeEmpty(filters);
    },
    clearTableFilters: (state) => {
      state.requestClientParam = {
        ...initialState.requestClientParam,
        filteredInfo: { my_client: state.requestClientParam.filteredInfo.my_client ?? [] },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchClients.pending, (state, _action) => {
        state.apiStatus = APIStatus.PENDING;
      })
      .addCase(fetchClients.fulfilled, (state, action) => {
        state.clients = action.payload.map<Client>((c) => ({
          ...c,
          risk_history: c.risk_history || [],
          sentiment: sentiment_status(c),
          last_callback: c.risk_history ? c.risk_history[0]?.last_callback : null,
          last_mood: c.risk_history ? c.risk_history[0]?.mood : null,
        }));
        state.apiStatus = APIStatus.FULFILLED;
      })
      .addCase(fetchClients.rejected, (state, action) => {
        state.apiStatus = APIStatus.ERROR;
        state.apiErrorText = (action.payload as ErrorMessage)?.displayText;
      });
  },
});

export const { handleTableChange, handleFiltersChange, clearTableFilters } = dashboardSlice.actions;
