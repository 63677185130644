import type { AxiosError } from 'axios';
import axios from 'axios';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { APIStatus } from '../../../enum';
import type { ErrorMessage } from '../../../models/error';
import type { AsyncThunkConfig } from '../../../models/slice';
import type { FilteredInfo, RequestParams, TableChange } from '../../../models/table';
import { SortedInfo } from '../../../models/table';
import { RaygunErrorHandlerService } from '../../../service/raygun.service';
import { removeEmpty } from '../../../service/table.service';

const { logError } = RaygunErrorHandlerService();

export class AdminClient {
  total_count: number = 0;
  account_status: string | null = null;
  address: string | null = null;
  city: string | null = null;
  country: string | null = null;
  date_of_birth: string | null = null;
  email: string | null = null;
  first_name: string | null = null;
  gender: string | null = null;
  home_phone: string | null = null;
  id: string | null = null;
  last_name: string | null = null;
  marital_status: string | null = null;
  middle_name: string | null = null;
  mobile_phone: string | null = null;
  name: string | null = null;
  organization_id: string | null = null;
  organization: string | null = null;
  race: string | null = null;
  state: string | null = null;
  zip_code: string | null = null;
  clinicians: string[] = [];
  clinician_ids: string[] = [];
  sites: string[] = [];
  site_ids: string[] = [];
  product_ids: string[] = [];
  products: string[] = [];
  notification?: boolean = false;
  medical_record_number: string | null = null;
  diagnoses: AdminDiagnosis[] | null = null;
  internal: boolean = false;
  organization_internal: boolean = false;
  expected_delivery_date: string | null = null;
  actual_delivery_date: string | null = null;
  study_id: string | null = null;
  secondary_phone: string | null = null;
  residential_area: string | null = null;
  home_clinic: string | null = null;
  temp_password?: string | null = null;
  created: string | null = null;
  inactive_days: number | null = null;
  insurance_carrier_id: string | null = null;
  insurance_carrier_name: string | null = null;
  patient_consent_form_acknowledgement: boolean | null = null;
  custom_fields: Record<string, string | null> | null = null;
  has_submission: boolean | null = null;
  client_program: string | null = null;
  client_type: string | null = null;
  last_check_in?: string | null;
}

export type AdminDiagnosis = {
  order: number;
  id: string;
  name: string;
};

export enum ADMIN_CLIENT_MODAL {
  PASSWORD_RESET = 'password_reset',
  IDLE = 'idle',
  ARCHIVE_CLIENT = 'archive_client',
}

type AdminClientSliceType = {
  adminClientData: AdminClient[];
  requestAdminClientParam: RequestParams<AdminClient>;
  adminClientApiStatus: APIStatus;
  adminAPIErrorText: string;
  adminClientModal: ADMIN_CLIENT_MODAL;
  adminClient: AdminClient;
};

const initialState: AdminClientSliceType = {
  adminClientData: [],
  requestAdminClientParam: {
    pagination: {
      current: 1,
      pageSize: 50,
      defaultPageSize: 50,
    },
    sortedInfo: new SortedInfo<AdminClient>('name', 'ascend'),
    filteredInfo: {},
  },
  adminClientApiStatus: APIStatus.IDLE,
  adminAPIErrorText: '',
  adminClientModal: ADMIN_CLIENT_MODAL.IDLE,
  adminClient: new AdminClient(),
};

export const fetchAdminClients = createAsyncThunk<AdminClient[], undefined, AsyncThunkConfig>(
  'adminClient/fetchAdminClients',
  async (_, thunkAPI) => {
    try {
      const { adminClientSlice } = thunkAPI.getState();
      const response = (await axios.post('v2_admin_client', adminClientSlice.requestAdminClientParam)) as AdminClient[];
      return response ?? [];
    } catch (e) {
      logError(e, ['adminClientSlice', 'fetchAdminClients']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

type SetInternal = {
  client_id: string;
  internal: boolean;
};

export const adminClientSetInternal = createAsyncThunk<boolean, SetInternal, AsyncThunkConfig>(
  'adminClient/adminClientSetInternal',
  async (setInternal, thunkAPI) => {
    try {
      const response = (await axios.post('v2_client_internal_set', setInternal)) as boolean;
      if (response) {
        thunkAPI.dispatch(fetchAdminClients());
      }
      return response ?? false;
    } catch (e) {
      logError(e, ['adminClientSlice', 'adminClientSetInternal']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const adminClientSlice = createSlice({
  name: 'adminClient',
  initialState,
  reducers: {
    handleClientTableChange: (state, action: PayloadAction<TableChange<AdminClient>>) => {
      const filters = action.payload.filters as FilteredInfo;
      const sorter = action.payload.sorter as SortedInfo<AdminClient>;
      if (!sorter.order) {
        sorter.order = null;
      }
      state.requestAdminClientParam.pagination = action.payload.pagination;
      state.requestAdminClientParam.sortedInfo = sorter;
      state.requestAdminClientParam.filteredInfo = removeEmpty(filters);
    },
    clearFilter: (state) => {
      state.requestAdminClientParam.pagination = initialState.requestAdminClientParam.pagination;
      state.requestAdminClientParam.sortedInfo = initialState.requestAdminClientParam.sortedInfo;
      state.requestAdminClientParam.filteredInfo = initialState.requestAdminClientParam.filteredInfo;
    },
    showEditClientModal: (state, action: PayloadAction<ADMIN_CLIENT_MODAL>) => {
      state.adminClientModal = action.payload;
    },
    setAdminClient: (state, action: PayloadAction<AdminClient>) => {
      state.adminClient = action.payload;
    },
    closeEditClientModal: (state) => {
      state.adminClientModal = ADMIN_CLIENT_MODAL.IDLE;
      state.adminClient = new AdminClient();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdminClients.pending, (state, _action) => {
        state.adminClientApiStatus = APIStatus.PENDING;
      })
      .addCase(fetchAdminClients.fulfilled, (state, action) => {
        state.adminClientData = action.payload;
        state.adminClientApiStatus = APIStatus.FULFILLED;
      })
      .addCase(fetchAdminClients.rejected, (state, action) => {
        state.adminClientApiStatus = APIStatus.ERROR;
        state.adminAPIErrorText = (action.payload as ErrorMessage)?.displayText;
      });
  },
});

export const { handleClientTableChange, clearFilter, closeEditClientModal, setAdminClient, showEditClientModal } =
  adminClientSlice.actions;
