import type { AxiosError } from 'axios';
import axios from 'axios';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import type { StatusKey } from '../../../enum';
import { APIStatus } from '../../../enum';
import type { ScoreDetail } from '../../../models/client';
import type { ErrorMessage } from '../../../models/error';
import type { AsyncThunkConfig } from '../../../models/slice';
import { RaygunErrorHandlerService } from '../../../service/raygun.service';

const { logError } = RaygunErrorHandlerService();

type ClientProfileIndicatorGraph = {
  recent_indicators: RecentIndicator[];
};

type ClientProfileMoods = {
  recent_moods: RecentMoods[];
};

type RecentMoods = {
  count: number;
  mood: string;
  mood_id: string;
};

export type ClientLocation = {
  id: string;
  client_id: string;
  speed: number;
  latitude: number;
  longitude: number;
  bearing: number | null;
  location_type: string;
  accuracy: number;
  heading: number;
  altitude: number;
  altitude_accuracy: number;
  time: number;
  organization_id: string;
  created: string;
  submission_id: string;
};

export class ClientProfileInfo {
  client_journal_id: string = '';
  last_journal_id: string = '';
  id: string = '';
  medical_record_number: string = '';
  organization_name: string = '';
  sites: string[] = [];
  created_date: string = '';
  diary: string | null = null;
  sentiment: number | null = null;
  status: StatusKey | null = null;
  mobile_phone: string = '';
  client_last_name: string = '';
  client_first_name: string = '';
  email: string = '';
  address: string = '';
  city: string = '';
  state: string = '';
  postalcode: string = '';
  client_last_location: ClientLocation | null = null;
  account_status: string = '';
  custom_fields: object | null = null;
  score: number | null = null;
  diagnoses: { id: string; name: string; order: number }[] = [];
}

export type ClientProfileSpiderChartInfo = {
  client_journal_id: string;
  product_short_name: string;
  assigned_journal_id: string;
  id: string;
  created_date: string;
  diary: string;
  status: string;
  fields: Fields[];
  max_field_value: number;
  min_field_value: number;
  account_status: string;
  custom_fields: object | null;
};

type Fields = {
  hide_in_radial: boolean;
  name: string;
  value: number;
};

export type RecentIndicator = {
  type: string;
  data: {
    count: number;
    response: string;
    time: string;
  }[];
  max: number;
  min: number;
};

export type APAClientIndicator = {
  product: string;
  client_journal_id: string;
  created_date: string;
  status: string;
  score: number;
  score_details: ScoreDetail;
  last_cravings: number;
  fields: APAField[];
};

type APAField = {
  name: string;
  value: string;
  status: string;
  img: string;
  numeric_value: number;
  product_field_id: string;
};

export class InterventionDetails {
  client_id: string = '';
  method: string = '';
  details: string = '';
}

export type RadarIndicatorlData = {
  name: string;
  min: number;
  max: number;
};

export class RadarData {
  name: string | undefined = undefined;
  value: number[] | undefined = undefined;
}

export type TabKey = 'entries' | 'badges' | 'graphs' | 'maps' | 'settings';

type ClientProfileSlideType = {
  activeTab: TabKey;
  clientProfileIndicatorGraph: ClientProfileIndicatorGraph;
  clientProfileSpiderInfo: ClientProfileSpiderChartInfo[];
  clientProfileMoods: ClientProfileMoods;
  clientProfileInfo: ClientProfileInfo;
  apaClientIndicators: APAClientIndicator[];
  clientRiskIndicatorApiStatus: APIStatus;
  clientMoodIndicatorApiStatus: APIStatus;
  clientSpiderIndicatorApiStatus: APIStatus;
  clientIndicatorApiStatus: APIStatus;
  apaClientIndicatorApiStatus: APIStatus;
  hasDevice: boolean;
  emailMailgunFailure: boolean;
  dateRange: string;
  chartStyle: string;
  interventionDetails: InterventionDetails;
  clientInterventionApiStatus: APIStatus;
  loading: boolean;
};

const initialState: ClientProfileSlideType = {
  activeTab: 'entries',
  clientProfileIndicatorGraph: {
    recent_indicators: [],
  },
  clientProfileMoods: {
    recent_moods: [],
  },
  clientProfileSpiderInfo: [],
  apaClientIndicators: [],
  clientProfileInfo: new ClientProfileInfo(),
  clientRiskIndicatorApiStatus: APIStatus.IDLE,
  clientMoodIndicatorApiStatus: APIStatus.IDLE,
  clientSpiderIndicatorApiStatus: APIStatus.IDLE,
  clientIndicatorApiStatus: APIStatus.IDLE,
  apaClientIndicatorApiStatus: APIStatus.IDLE,
  hasDevice: false,
  emailMailgunFailure: false,
  dateRange: '2 weeks',
  chartStyle: 'lineChart',
  interventionDetails: new InterventionDetails(),
  clientInterventionApiStatus: APIStatus.IDLE,
  loading: false,
};

export const fetchClientProfileGraphs = createAsyncThunk<ClientProfileIndicatorGraph, string, AsyncThunkConfig>(
  'clientProfile/fetchClientProfileGraphs',
  async (client_id, thunkAPI) => {
    try {
      const { clientProfileSlice } = thunkAPI.getState();
      const response = (await axios.post('v3_client_profile_recent_indicators', {
        client_id: client_id,
        interval: clientProfileSlice.dateRange,
      })) as ClientProfileIndicatorGraph;
      return response ?? initialState.clientProfileIndicatorGraph;
    } catch (e) {
      logError(e, ['clientProfileSlice', 'fetchClientProfileGraphs']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const fetchClientProfileMoods = createAsyncThunk<ClientProfileMoods, string, AsyncThunkConfig>(
  'clientProfile/fetchClientProfileMoods',
  async (client_id, thunkAPI) => {
    try {
      const response = (await axios.post('v3_client_profile_recent_moods', {
        client_id: client_id,
        interval: '6 weeks',
      })) as ClientProfileMoods;
      return response ?? initialState.clientProfileMoods;
    } catch (e) {
      logError(e, ['clientProfileSlice', 'fetchClientProfileMoods']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const fetchClientProfileInfo = createAsyncThunk<ClientProfileInfo, string, AsyncThunkConfig>(
  'clientProfile/fetchClientProfileInfo',
  async (client_id, thunkAPI) => {
    try {
      const response = (await axios.post('v2_client_profile', {
        client_id: client_id,
      })) as ClientProfileInfo[];
      const email = response[0]?.email;
      if (email) {
        thunkAPI.dispatch(fetchMailGunStatus(email));
      }
      return response[0] ?? initialState.clientProfileInfo;
    } catch (e) {
      logError(e, ['clientProfileSlice', 'fetchClientProfileInfo']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const fetchClientSpiderChartInfo = createAsyncThunk<ClientProfileSpiderChartInfo[], string, AsyncThunkConfig>(
  'clientProfile/fetchClientSpiderChartInfo',
  async (client_id, thunkAPI) => {
    try {
      const response = (await axios.post('v3_client_spider_indicator_info', {
        client_id: client_id,
      })) as ClientProfileSpiderChartInfo[];
      return response ?? [];
    } catch (e) {
      logError(e, ['clientProfileSlice', 'fetchClientSpiderChartInfo']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const fetchAPAClientProfileGraph = createAsyncThunk<APAClientIndicator[], string, AsyncThunkConfig>(
  'clientProfile/fetchAPAClientProfileGraph',
  async (client_id, thunkAPI) => {
    try {
      const response = (await axios.post('v2_client_profile_apa', {
        client_id: client_id,
      })) as APAClientIndicator[];
      return response ?? [];
    } catch (e) {
      logError(e, ['clientProfileSlice', 'fetchAPAClientProfileGraph']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const fetchMailGunStatus = createAsyncThunk<boolean, string, AsyncThunkConfig>(
  'clientProfile/fetchMailGunStatus',
  async (email, thunkAPI) => {
    try {
      const response = (await axios.post('v2_client_email_failure', { email })) as boolean;
      return response;
    } catch (e) {
      logError(e, ['clientProfileSlice', 'fetchMailGunStatus']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const fetchDeviceStatus = createAsyncThunk<boolean, string, AsyncThunkConfig>(
  'clientProfile/fetchDeviceStatus',
  async (client_id, thunkAPI) => {
    try {
      const response = (await axios.post('v3_client_valid_device_status', { client_id: client_id })) as boolean;
      return response;
    } catch (e) {
      logError(e, ['clientProfileSlice', 'fetchDeviceStatus']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const postIntervention = createAsyncThunk<string, InterventionDetails, AsyncThunkConfig>(
  'clientProfile/postIntervention',
  async (interventionDetails, thunkAPI) => {
    try {
      const response = (await axios.post('v2_intervention_record', interventionDetails)) as string;
      if (response) {
        window.location.href = interventionDetails.details;
      }
      return response; // client_id
    } catch (e) {
      logError(e, ['clientProfileSlice', 'postIntervention']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const clientProfileSlice = createSlice({
  name: 'clientProfile',
  initialState,
  reducers: {
    setActiveTab: (state, action: PayloadAction<TabKey>) => {
      state.activeTab = action.payload;
    },
    setDateRange: (state, action: PayloadAction<string>) => {
      state.dateRange = action.payload;
    },
    setChartStyle: (state, action: PayloadAction<string>) => {
      state.chartStyle = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMailGunStatus.fulfilled, (state, action) => {
        state.clientIndicatorApiStatus = APIStatus.FULFILLED;
        state.emailMailgunFailure = action.payload;
      })
      .addCase(fetchMailGunStatus.rejected, (state, _action) => {
        // Ignore failures of fetchMailGunStatus
        state.clientIndicatorApiStatus = APIStatus.FULFILLED;
      })
      .addCase(fetchDeviceStatus.fulfilled, (state, action) => {
        state.clientIndicatorApiStatus = APIStatus.FULFILLED;
        state.hasDevice = action.payload;
      })
      .addCase(fetchClientProfileGraphs.pending, (state, _action) => {
        state.clientRiskIndicatorApiStatus = APIStatus.PENDING;
      })
      .addCase(fetchClientProfileGraphs.fulfilled, (state, action) => {
        state.clientProfileIndicatorGraph = action.payload;
        state.clientRiskIndicatorApiStatus = APIStatus.FULFILLED;
      })
      .addCase(fetchClientProfileMoods.pending, (state, _action) => {
        state.clientMoodIndicatorApiStatus = APIStatus.PENDING;
      })
      .addCase(fetchClientProfileMoods.fulfilled, (state, action) => {
        state.clientProfileMoods = action.payload;
        state.clientMoodIndicatorApiStatus = APIStatus.FULFILLED;
      })
      .addCase(fetchClientProfileInfo.fulfilled, (state, action) => {
        state.clientProfileInfo = action.payload;
      })
      .addCase(fetchClientSpiderChartInfo.pending, (state, _action) => {
        state.clientSpiderIndicatorApiStatus = APIStatus.PENDING;
      })
      .addCase(fetchClientSpiderChartInfo.fulfilled, (state, action) => {
        state.clientProfileSpiderInfo = action.payload;
        state.clientSpiderIndicatorApiStatus = APIStatus.FULFILLED;
      })
      .addCase(fetchAPAClientProfileGraph.pending, (state, _action) => {
        state.apaClientIndicatorApiStatus = APIStatus.PENDING;
      })
      .addCase(fetchAPAClientProfileGraph.fulfilled, (state, action) => {
        state.apaClientIndicators = action.payload;
        state.apaClientIndicatorApiStatus = APIStatus.FULFILLED;
      })
      .addCase(postIntervention.pending, (state) => {
        state.clientInterventionApiStatus = APIStatus.PENDING;
      })
      .addCase(postIntervention.fulfilled, (state) => {
        state.clientInterventionApiStatus = APIStatus.FULFILLED;
      });
  },
});

export const { setActiveTab, setDateRange, setChartStyle, setLoading } = clientProfileSlice.actions;
