import type { AxiosError } from 'axios';
import axios from 'axios';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { APIStatus } from '../../../../../enum';
import type { ErrorMessage } from '../../../../../models/error';
import type { AsyncThunkConfig } from '../../../../../models/slice';
import type { ClientLocation } from '../../clientProfileSlice';

type MapParams = {
  client_id: string;
  end_date: Dayjs;
  start_date: Dayjs;
};

export type MapData = {
  mapData: ClientLocation[];
  mapApiStatus: APIStatus;
  mapParams: MapParams;
};

const initialState: MapData = {
  mapData: [],
  mapApiStatus: APIStatus.IDLE,
  mapParams: {
    client_id: '',
    start_date: dayjs().subtract(2, 'weeks'),
    end_date: dayjs(),
  },
};

export const fetchLocationHistory = createAsyncThunk<ClientLocation[], undefined, AsyncThunkConfig>(
  'map/fetchLocationHistory',
  async (_, thunkAPI) => {
    try {
      const mapParams = thunkAPI.getState().mapSlice.mapParams;
      const response = (await axios.post('v2_client_location_history', mapParams)) as ClientLocation[];
      return response ?? [];
    } catch (e) {
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    handleParamsChange: (state, action) => {
      state.mapParams = { ...state.mapParams, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLocationHistory.pending, (state, _action) => {
        state.mapApiStatus = APIStatus.PENDING;
      })
      .addCase(fetchLocationHistory.fulfilled, (state, action) => {
        state.mapData = action.payload;
        state.mapApiStatus = APIStatus.FULFILLED;
      })
      .addCase(fetchLocationHistory.rejected, (state, _action) => {
        state.mapApiStatus = APIStatus.ERROR;
      });
  },
});

export const { handleParamsChange } = mapSlice.actions;
