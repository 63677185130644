import { notification } from 'antd';
import type { AxiosError } from 'axios';
import axios from 'axios';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { APIStatus } from '../../../enum';
import type { ErrorMessage } from '../../../models/error';
import type { AsyncThunkConfig } from '../../../models/slice';
import { RaygunErrorHandlerService } from '../../../service/raygun.service';
import { isMKI } from '../Login/login.helper';

const { logError } = RaygunErrorHandlerService();

export class Resource {
  active: boolean | null = null;
  alias: string | null = null;
  copy: string | null = null;
  id: string | null = null;
  language_code: string | null = null;
  name: string | null = null;
  order: number | null = null;
  type: string | null = null;
  assigned_organization_ids: string[] = [];
  assigned_organizations_name: string[] = [];
}

export enum RESOURCE_MODAL {
  IDLE = 'idle',
  EDIT_RESOURCE = 'edit_resource',
  ADD_RESOURCE = 'add_resources',
}

type ResourcesSliceType = {
  resourcesData: Resource[];
  resourcesApiStatus: APIStatus;
  resourcesAPIErrorText: string;
  resource: Resource;
  modal: RESOURCE_MODAL;
};

const initialState: ResourcesSliceType = {
  resourcesData: [],
  resourcesApiStatus: APIStatus.IDLE,
  resourcesAPIErrorText: '',
  resource: new Resource(),
  modal: RESOURCE_MODAL.IDLE,
};

export const fetchResources = createAsyncThunk<Resource[], undefined, AsyncThunkConfig>(
  'resources/fetchResources',
  async (_, thunkAPI) => {
    try {
      const response = (await axios.post('v3_resource')) as Resource[];
      const {
        authSlice: {
          currentUser: { organization_id },
        },
      } = thunkAPI.getState();
      if (organization_id && isMKI(organization_id)) {
        const result = response.map((r) => {
          switch (r.alias) {
            case 'whats_new?_portal':
              r.name = 'Guidelines';
              break;
            case 'faq_portal':
              r.name = 'Call list';
              break;
          }
          return r;
        });
        return result;
      }
      return response;
    } catch (e) {
      logError(e, ['resourcesSlice', 'fetchResources']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const updateResource = createAsyncThunk<string, Resource, AsyncThunkConfig>(
  'resources/updateResource',
  async (resource, thunkAPI) => {
    try {
      const response = (await axios.post('v3_resource_upsert', resource)) as string;
      thunkAPI.dispatch(setResourceModal(RESOURCE_MODAL.IDLE));
      thunkAPI.dispatch(createNewResource());
      if (response) {
        thunkAPI.dispatch(fetchResources());
        notification.destroy();
        notification.success({
          message: 'Resource updated',
          description: 'Success',
        });
        return response;
      } else {
        notification.destroy();
        notification.error({
          message: 'Resource updated',
          description: 'Failure',
        });
        return '';
      }
    } catch (e) {
      logError(e, ['resourcesSlice', 'updateResource']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const resourcesSlice = createSlice({
  name: 'resources',
  initialState,
  reducers: {
    setResourceModal: (state, action: PayloadAction<RESOURCE_MODAL>) => {
      state.modal = action.payload;
    },
    setResource: (state, action: PayloadAction<Resource>) => {
      state.resource = action.payload;
    },
    createNewResource: (state) => {
      state.resource = new Resource();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchResources.pending, (state, _action) => {
        state.resourcesApiStatus = APIStatus.PENDING;
      })
      .addCase(fetchResources.fulfilled, (state, action) => {
        state.resourcesData = action.payload;
        state.resourcesApiStatus = APIStatus.FULFILLED;
      })
      .addCase(fetchResources.rejected, (state, action) => {
        state.resourcesApiStatus = APIStatus.ERROR;
        state.resourcesAPIErrorText = (action.payload as ErrorMessage)?.displayText;
      });
  },
});

export const { setResourceModal, setResource, createNewResource } = resourcesSlice.actions;
