export const environment = {
  baseEndpoint: "https://api-dev.trycycle.io/admin/",  
  secondEndpoint: "https://api-dev.trycycle.io/admin/",  
  googleMapsKey: 'AIzaSyCfcEcQQiRossHyIq3X0_YwUr22GYRReNc',  
  buildNumber: "28900595",  
  versionNumber: "5.3.10",  
  environment_deploy: "dev",  
  rayGunKey: '43wdWdJaHTd1ENpkAYZ53g',  
  authKey: 'd1d79943-e2c8-453c-8cc9-ef99e4d8ebd7',
  cryptoKey: '10542039-2ee6-4545-a57c-696e1a69cda6',  
  chromiumMinNumber: '110',
  safariMinNumber: '16',
  parrotPosthogToken: 'phc_mRbixOQM4BkdIj6YXKcg46lDqXy7XcsEmdSSZzFZfM6', 
};
