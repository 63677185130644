import { notification } from 'antd';

import { UserRole } from '../../../enum';
import type { AdminClinician } from './adminClinicianSlice';

export const validateAdminClinician = (adminClinician: AdminClinician) => {
  const phoneRegex = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;

  if (
    !adminClinician.first_name ||
    !adminClinician.last_name ||
    !adminClinician.role ||
    !adminClinician.email ||
    !adminClinician.job_id ||
    (adminClinician.role !== UserRole.ORGANIZATION && (!adminClinician.site_ids || adminClinician.site_ids.length < 1))
  ) {
    notification.error({
      message: 'Missing required fields',
      description: 'Please provide values for all required fields.',
    });
    return false;
  } else if (!adminClinician.email.match(/^[^@]+@[^@]+\.\w\w+$/g)) {
    notification.error({
      message: 'Invalid email',
      description: 'Please provide a valid email.',
    });
    return false;
  } else if (adminClinician.mobile_phone && !adminClinician.mobile_phone.match(phoneRegex)) {
    notification.error({
      message: 'Invalid mobile phone number',
      description: 'Please provide a valid mobile phone number.',
    });
    return false;
  } else {
    return true;
  }
};
