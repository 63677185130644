import type { AxiosError } from 'axios';
import axios from 'axios';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { APIStatus } from '../../../enum';
import type { ErrorMessage } from '../../../models/error';
import type { AsyncThunkConfig } from '../../../models/slice';
import type { FilteredInfo, RequestParams, TableChange } from '../../../models/table';
import { SortedInfo } from '../../../models/table';
import { RaygunErrorHandlerService } from '../../../service/raygun.service';
import { removeEmpty } from '../../../service/table.service';
import type { AdminClient } from '../admin-clients/adminClientSlice';

const { logError } = RaygunErrorHandlerService();

type InActiveClientSliceType = {
  inActiveClientData: AdminClient[];
  requestInActiveClientParam: RequestParams<AdminClient>;
  inActiveApiStatus: APIStatus;
  inActiveAPIErrorText: string;
};

const initialState: InActiveClientSliceType = {
  inActiveClientData: [],
  requestInActiveClientParam: {
    pagination: {
      current: 1,
      pageSize: 50,
      defaultPageSize: 50,
    },
    sortedInfo: new SortedInfo<AdminClient>('inactive_days', 'descend'),
    filteredInfo: {},
  },
  inActiveApiStatus: APIStatus.IDLE,
  inActiveAPIErrorText: '',
};

export const fetchInActiveClients = createAsyncThunk<AdminClient[], undefined, AsyncThunkConfig>(
  'inActive/fetchInActiveClients',
  async (_, thunkAPI) => {
    try {
      const { inActiveSlice } = thunkAPI.getState();
      const response = await axios.post('v3_inactive_clients', inActiveSlice.requestInActiveClientParam);
      return (response as unknown as AdminClient[]) ?? [];
    } catch (e) {
      logError(e, ['inActiveSlice', 'fetchInActiveClients']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const inActiveSlice = createSlice({
  name: 'inActive',
  initialState,
  reducers: {
    handleInActiveClientTableChange: (state, action: PayloadAction<TableChange<AdminClient>>) => {
      const filters = action.payload.filters as FilteredInfo;
      const sorter = action.payload.sorter as SortedInfo<AdminClient>;
      if (!sorter.order) {
        sorter.order = null;
      }
      state.requestInActiveClientParam.pagination = action.payload.pagination;
      state.requestInActiveClientParam.sortedInfo = sorter;
      state.requestInActiveClientParam.filteredInfo = removeEmpty(filters);
    },
    clearFilter: (state) => {
      state.requestInActiveClientParam.pagination = initialState.requestInActiveClientParam.pagination;
      state.requestInActiveClientParam.sortedInfo = initialState.requestInActiveClientParam.sortedInfo;
      state.requestInActiveClientParam.filteredInfo = initialState.requestInActiveClientParam.filteredInfo;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInActiveClients.pending, (state, _action) => {
        state.inActiveApiStatus = APIStatus.PENDING;
      })
      .addCase(fetchInActiveClients.fulfilled, (state, action) => {
        state.inActiveClientData = action.payload;
        state.inActiveApiStatus = APIStatus.FULFILLED;
      })
      .addCase(fetchInActiveClients.rejected, (state, action) => {
        state.inActiveApiStatus = APIStatus.ERROR;
        state.inActiveAPIErrorText = (action.payload as ErrorMessage)?.displayText;
      });
  },
});

export const { handleInActiveClientTableChange, clearFilter } = inActiveSlice.actions;
