import type { AxiosError } from 'axios';
import axios from 'axios';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { APIStatus, UserRole } from '../../../enum';
import type { ErrorMessage } from '../../../models/error';
import type { AsyncThunkConfig } from '../../../models/slice';
import { RaygunErrorHandlerService } from '../../../service/raygun.service';
import type { Status } from '../client-profile/client-tabs/graphs/graphSlice';
import type {
  CheckIn,
  ClientEngagementDetail,
  ClientNoNotification,
  ClientNotOnboarded,
  NewClient,
  TotalClientDetail,
} from './modals/modalDashboardSlice';
import { dateTimeFilterFormat } from './program-dashboard.helper';

const { logError } = RaygunErrorHandlerService();

type DashboardRequestParams = {
  end_date: Dayjs;
  start_date: Dayjs;
  site_id?: string;
  diagnosis_id?: string;
  organization_id?: string;
};

type DashboardRequestBody = {
  end_date: string;
  start_date: string;
  site_id: string | null;
  diagnosis_id?: string | null;
  organization_id?: string | null;
};

export type NameValue = {
  name: string;
  value: number;
};

export type NameTotal = Record<'age_group' | 'primary_diagnosis' | 'gender', string> & {
  total: number;
};

export class BarLineData {
  constructor(
    public name: string | null,
    public type: 'bar' | 'category',
    public data: number[] | string[],
  ) {
    this.name = name;
    this.type = type;
    this.data = data;
  }
}

export const InterventionType = {
  HighSeverity: 'High severity',
  MediumSeverity: 'Medium severity',
  LowSeverity: 'Low severity',
  FeedbackOnly: 'Feedback only',
} as const;

export type InterventionValue = (typeof InterventionType)[keyof typeof InterventionType];

export type Intervention = {
  intervention_id: string;
  client_name: string;
  sites: string[];
  clinician_name: string;
  intervention_type: InterventionValue;
  date: string;
};

export type TimeInStatus = {
  client_id: string;
  full_name: string;
  sites: string[];
  products: string[];
  cumulative_minutes_in_status: Record<Status, number>;
};

export type DashboardMoods = {
  count: number;
  mood:
    | 'Angry'
    | 'Bored'
    | 'Content'
    | 'Good'
    | 'Grateful'
    | 'Grounded'
    | 'Guilty'
    | 'Lonely'
    | 'Proud'
    | 'Sad'
    | 'Tired'
    | 'Worried'
    | 'Other';
  mood_id: string;
};

export type MostCommonWordCount = {
  word: string;
  frequency: number;
};

type ProgramDashboardData = {
  end_date?: Dayjs;
  start_date?: Dayjs;
  site_id?: string;
  client_engagement: number | null;
  client_engagement_details: ClientEngagementDetail[];
  check_ins: CheckIn[] | null;
  total_clients_details: TotalClientDetail[] | null;
  new_clients: NewClient[] | null;
  issues: {
    number_of_clients_with_no_activity: number | null;
    clients_with_no_devices: ClientNoNotification[] | null;
    clients_who_did_not_onboard: ClientNotOnboarded[] | null;
  };
  check_in_distribution_by_dow_hour: [number, number, number][];
  client_activity_trends: {
    sentiment_counts: NameValue[];
    interventions: Intervention[];
    status_distribution: TimeInStatus[];
    mood_counts: DashboardMoods[];
    most_common_word_counts: MostCommonWordCount[];
  };
  population_breakdown: {
    product_distribution: NameValue[];
    gender_distribution: NameTotal[];
    age_distribution: NameTotal[];
    primary_diagnosis_distribution: NameTotal[];
  };
};

type ProgramDashboardSlideType = {
  programDashboardParams: DashboardRequestParams;
  programDashboardData: ProgramDashboardData;
  apiStatus: string;
};

const initialState: ProgramDashboardSlideType = {
  programDashboardParams: {
    start_date: dayjs().subtract(1, 'month'),
    end_date: dayjs(),
  },
  programDashboardData: {
    client_engagement: null,
    client_engagement_details: [],
    check_ins: null,
    total_clients_details: null,
    new_clients: null,
    issues: {
      number_of_clients_with_no_activity: null,
      clients_with_no_devices: null,
      clients_who_did_not_onboard: null,
    },
    check_in_distribution_by_dow_hour: [],
    client_activity_trends: {
      sentiment_counts: [],
      interventions: [],
      status_distribution: [],
      mood_counts: [],
      most_common_word_counts: [],
    },
    population_breakdown: {
      product_distribution: [],
      gender_distribution: [],
      age_distribution: [],
      primary_diagnosis_distribution: [],
    },
  },
  apiStatus: APIStatus.IDLE,
};

export const loadDashboard = createAsyncThunk<ProgramDashboardData, undefined, AsyncThunkConfig>(
  'programDashboard/loadDashboard',
  async (_, thunkAPI) => {
    try {
      const {
        programDashboardSlice: { programDashboardParams },
        authSlice: { currentUser },
      } = thunkAPI.getState();
      const body: DashboardRequestBody = {
        ...programDashboardParams,
        start_date: programDashboardParams.start_date.startOf('day').format(dateTimeFilterFormat),
        end_date: programDashboardParams.end_date.endOf('day').format(dateTimeFilterFormat),
        site_id: programDashboardParams.site_id || null,
        organization_id: programDashboardParams.organization_id || null,
      };
      if (currentUser.role === UserRole.ADMIN) {
        body.organization_id = programDashboardParams.organization_id || null;
      }

      const response = await axios.post('v3_program_dashboard', body);
      return (response as unknown as ProgramDashboardData) ?? initialState.programDashboardData;
    } catch (e) {
      logError(e, ['programDashboardSlice', 'loadDashboard']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);
export const programDashboardSlice = createSlice({
  name: 'programDashboard',
  initialState,
  reducers: {
    handleParamsChange: (state, action: PayloadAction<Partial<DashboardRequestParams>>) => {
      state.programDashboardParams = { ...state.programDashboardParams, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadDashboard.pending, (state, _action) => {
        state.apiStatus = APIStatus.PENDING;
      })
      .addCase(loadDashboard.fulfilled, (state, action) => {
        state.programDashboardData = action.payload;
        state.programDashboardData.site_id = state.programDashboardParams.site_id;
        state.programDashboardData.start_date = state.programDashboardParams.start_date;
        state.programDashboardData.end_date = state.programDashboardParams.end_date;
        state.apiStatus = APIStatus.FULFILLED;
      })
      .addCase(loadDashboard.rejected, (state, _action) => {
        state.apiStatus = APIStatus.ERROR;
      });
  },
});

export const { handleParamsChange } = programDashboardSlice.actions;
