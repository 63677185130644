import { notification } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import type { Middleware, UnknownAction } from '@reduxjs/toolkit';
import { combineReducers, configureStore, isAction } from '@reduxjs/toolkit';

import { clientSetTempPasswordSlice } from './components/modals/clientSetTempPassword/clientSetTempPasswordSlice';
import { adminAddProductSlice } from './components/pages/admin-add-product/adminAddProductSlice';
import { adminClientSlice } from './components/pages/admin-clients/adminClientSlice';
import { clientBulkActionSlice } from './components/pages/admin-clients/bulk-assignment/bulkActionSlice';
import { adminClinicianSlice } from './components/pages/admin-clinicians/adminClinicianSlice';
import { clinicianBulkAssignmentSlice } from './components/pages/admin-clinicians/bulk-assignment/bulkAssignmentSlice';
import { adminJournalSlice } from './components/pages/admin-journals/adminJournalSlice';
import { adminOrganizationSlice } from './components/pages/admin-organization/adminOrganizationSlice';
import { adminSitesSlice } from './components/pages/admin-sites/adminSitesSlice';
import { clientBadgeSlice } from './components/pages/client-profile/client-tabs/badges/clientBadgeSlice';
import { clientEntriesSlice } from './components/pages/client-profile/client-tabs/entries/entriesSlice';
import { clientEntriesModalSlice } from './components/pages/client-profile/client-tabs/entries/entryModal/entriesModalSlice';
import { graphSlice } from './components/pages/client-profile/client-tabs/graphs/graphSlice';
import { mapSlice } from './components/pages/client-profile/client-tabs/map/mapSlice';
import { clientProfileSlice } from './components/pages/client-profile/clientProfileSlice';
import { clientUpsertSlice } from './components/pages/client-upsert/clientUpsertSlice';
import { clinicianDashboardSlice } from './components/pages/clinician-dashboard/clinicianDashboardSlice';
import { dashboardSlice } from './components/pages/dashboard/dashboardSlice';
import { hhcWeekendSlice } from './components/pages/hhc-weekend-schedule/hhcWeekendScheduleSlice';
import { inActiveSlice } from './components/pages/inactive-clients/inActiveSlice';
import { interventionSlice } from './components/pages/intervention/interventionSlice';
import { authSlice, logout, refreshToken } from './components/pages/Login/authSlice';
import { modalDashboardSlice } from './components/pages/program-dashboard/modals/modalDashboardSlice';
import { programDashboardSlice } from './components/pages/program-dashboard/programDashboardSlice';
import { adminReportSlice } from './components/pages/report/adminReportSlice';
import { resetPasswordSlice } from './components/pages/reset-password/resetPasswordSlice';
import { resourcesSlice } from './components/pages/resources/resourceSlice';
import { scheduledProductsSlice } from './components/pages/scheduled-products/scheduledProductsSlice';
import { validationSlice } from './components/pages/validation/validationSlice';
import { weeklyCompletionYaleSlice } from './components/pages/weekly-completion-yale/weeklyCompletionYaleSlice';
import { environment } from './environment/environment';
import { CryptoService } from './service/crypto.service';
import { RaygunErrorHandlerService } from './service/raygun.service';
import { tableSearchSlice } from './service/tableSearchSlice';
import { tableSlice } from './service/tableSlice';

dayjs.extend(utc);
dayjs.extend(timezone);

const isLocal = environment.environment_deploy === 'local';
const isTestInCI = environment.environment_deploy === 'ci';
const { logError } = RaygunErrorHandlerService();

const reducers = combineReducers({
  adminAddProductSlice: adminAddProductSlice.reducer,
  adminClientSlice: adminClientSlice.reducer,
  adminClinicianSlice: adminClinicianSlice.reducer,
  adminJournalSlice: adminJournalSlice.reducer,
  adminOrganizationSlice: adminOrganizationSlice.reducer,
  adminReportSlice: adminReportSlice.reducer,
  adminSitesSlice: adminSitesSlice.reducer,
  authSlice: authSlice.reducer,
  clientBadgeSlice: clientBadgeSlice.reducer,
  clientBulkActionSlice: clientBulkActionSlice.reducer,
  clientEntriesModalSlice: clientEntriesModalSlice.reducer,
  clientEntriesSlice: clientEntriesSlice.reducer,
  clientProfileSlice: clientProfileSlice.reducer,
  clientSetTempPasswordSlice: clientSetTempPasswordSlice.reducer,
  clientUpsertSlice: clientUpsertSlice.reducer,
  clinicianBulkAssignmentSlice: clinicianBulkAssignmentSlice.reducer,
  clinicianDashboardSlice: clinicianDashboardSlice.reducer,
  dashboardSlice: dashboardSlice.reducer,
  graphSlice: graphSlice.reducer,
  hhcWeekendSlice: hhcWeekendSlice.reducer,
  inActiveSlice: inActiveSlice.reducer,
  interventionSlice: interventionSlice.reducer,
  mapSlice: mapSlice.reducer,
  modalDashboardSlice: modalDashboardSlice.reducer,
  programDashboardSlice: programDashboardSlice.reducer,
  resetPasswordSlice: resetPasswordSlice.reducer,
  resourcesSlice: resourcesSlice.reducer,
  scheduledProductsSlice: scheduledProductsSlice.reducer,
  tableSearchSlice: tableSearchSlice.reducer,
  tableSlice: tableSlice.reducer,
  validationSlice: validationSlice.reducer,
  weeklyCompletionYaleSlice: weeklyCompletionYaleSlice.reducer,
});

export type StateType = ReturnType<typeof reducers>;

export const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

const rootReducer = (state: StateType | undefined, action: UnknownAction) => {
  // https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store
  if (action.type === 'LOG_OUT') {
    return reducers(undefined, action);
  }

  return reducers(state, action);
};

const saveAuth = async (state: Pick<StateType, 'authSlice'>) => {
  try {
    const encryptedAuth = await CryptoService.encrypt(JSON.stringify(state.authSlice), environment.cryptoKey);
    localStorage.setItem(environment.authKey, encryptedAuth);
  } catch (error) {
    logError(error, ['store', 'saveAuth']);
  }
};

const { baseEndpoint, secondEndpoint, versionNumber, buildNumber } = environment;

axios.defaults.baseURL = baseEndpoint;
axios.defaults.responseType = 'json';
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['appversion'] = `${versionNumber}.${buildNumber}`;

const Interceptor = (createdStore: typeof store) => {
  const { dispatch } = createdStore;

  axios.interceptors.request.use(
    async (config) => {
      config.headers['timezone_name'] = dayjs.tz.guess();
      config.headers['content-location'] = dayjs.tz.guess();

      const { resetPasswordSlice, authSlice } = createdStore.getState();

      if (
        config.url === 'v2_token_verify' ||
        (resetPasswordSlice.tokenFromPath && config.url === 'v2_password_reset')
      ) {
        return config;
      }

      if (config.baseURL === secondEndpoint && authSlice.authResult2 && authSlice.authResult2.token) {
        config.headers['Authorization'] = `Bearer ${authSlice.authResult2.token}`;
      } else if (authSlice.authResult.token) {
        config.headers['Authorization'] = `Bearer ${authSlice.authResult.token}`;
      }

      if (config.method === 'post' && !config.data) {
        config.data = {};
      }

      return config;
    },
    (error) => Promise.reject(error),
  );
  axios.interceptors.response.use(
    (response) => {
      return Promise.resolve(response.data);
    },
    (error) => {
      const online = navigator.onLine;
      const config = error.response?.config;
      if (!online) {
        notification.error({
          message: 'Offline',
          description: 'No internet connection.',
        });
      } else if (error.response && error.response.status === 401 && config?.url !== 'v2_token_verify') {
        dispatch(logout());
      } else if (!axios.isCancel(error)) {
        let errorTitle = error.message || 'Error';
        let errorDetails = 'Oops. Something went wrong.';
        errorDetails += ' Please contact a system administrator if the problem persists.';
        if (error.response) {
          if (error.response.data && error.response.data.customTitle) {
            errorTitle = error.response.data.customTitle;
          } else if (error.response.status === 422) {
            errorTitle = 'Invalid input';
          } else if (error.response.status === 404) {
            errorTitle = 'Not found';
            if (error.response.data.statusText === 'Clinician email not found') {
              errorDetails = 'The specified email was not found.';
            }
          }
          if (error.response.data && error.response.data.displayText) {
            errorDetails = error.response.data.displayText;
          }
        }

        const { resetPasswordSlice } = createdStore.getState();

        if (
          !(
            config?.url === 'v2_token_verify' ||
            (resetPasswordSlice.tokenFromPath && config?.url === 'v2_password_reset')
          )
        ) {
          notification.error({
            message: errorTitle,
            description: errorDetails,
            duration: errorDetails.length > 150 ? 8 : 4.5,
          });
        }
      }

      return Promise.reject(error);
    },
  );
};

const tokenRefresh: Middleware = ({
  dispatch,
  getState,
}: {
  dispatch: typeof store.dispatch;
  getState: typeof store.getState;
}) => {
  return (next) => (action) => {
    if (isAction(action) && !action?.type.includes('auth/')) {
      if (getState().authSlice && getState().authSlice.authResult.token) {
        const tokenExpiration = getState().authSlice.currentUser.exp;
        if (tokenExpiration && new Date(tokenExpiration * 1000).getTime() - new Date().getTime() < 20 * 60 * 1000) {
          return Promise.resolve(dispatch(refreshToken())).then(() => next(action));
        }
      }
    }
    return next(action);
  };
};
export const createStore = () => {
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: !isTestInCI,
      }).concat(tokenRefresh),
    devTools: isLocal,
  });

  Interceptor(store);

  return store;
};

const store = createStore();

let prevState = store.getState().authSlice;
store.subscribe(() => {
  const currentState = store.getState().authSlice;
  if (currentState !== prevState) {
    saveAuth({
      authSlice: currentState,
    });
    prevState = currentState;
  }
});

export default store;
