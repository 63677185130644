import { createHashRouter } from 'react-router';
import { RouterProvider } from 'react-router/dom';

import routerWindow from './components/sharedComponents/CustomPrompt/routerWindow';
import { ErrorBoundary } from './errorBoundary';
import { useAppDispatch, useAppSelector } from './hooks';
import { useCheckBrowserUpdate } from './hooks/useCheckBrowserUpdate';
import useInitApp from './hooks/useInitApp';
import { createRoute } from './routes/routes';

export const App = () => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector((state) => state.authSlice.currentUser);
  const authResult = useAppSelector((state) => state.authSlice.authResult);
  const routes = createRoute(currentUser, authResult, dispatch);
  const router = createHashRouter(routes, { window: routerWindow });
  useInitApp();
  useCheckBrowserUpdate();

  return (
    <ErrorBoundary dispatch={dispatch}>
      <RouterProvider router={router} />
    </ErrorBoundary>
  );
};
