import type { AxiosError } from 'axios';
import axios from 'axios';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { APIStatus } from '../../../../../enum';
import type { ErrorMessage } from '../../../../../models/error';
import type { AsyncThunkConfig } from '../../../../../models/slice';
import { RaygunErrorHandlerService } from '../../../../../service/raygun.service';
import type { TrendType } from './trends';

const { logError } = RaygunErrorHandlerService();

type Trend = {
  field_id?: string;
  measurement_label?: string;
  entries: { time: string; value: number }[];
};

export type Status = 'red' | 'yellow' | 'green' | 'na' | 'archive';

export type StatusTime = {
  name: Status;
  value: number;
};

export type DonutData = (
  | {
      status: 'red';
      name: 'High risk' | 'High';
      itemStyle: {
        color: '#ce0b24';
      };
    }
  | {
      status: 'yellow';
      name: 'Medium risk' | 'Medium';
      itemStyle: {
        color: '#f3a536';
      };
    }
  | {
      status: 'green';
      name: 'green' | 'Low risk' | 'Low';
      itemStyle: {
        color: '#81d135';
      };
    }
  | {
      status: 'na';
      name: 'Not logged';
      itemStyle: {
        color: '#4b4b4b';
      };
    }
  | {
      status: 'archive';
      name: 'Archived';
      itemStyle: {
        color: 'black';
      };
    }
) & {
  value: number;
};

type CalendarData = {
  score: number;
  status: Status;
  timestamp: string;
};

export type ChartType = 'calendar' | 'indicators' | 'bam17' | 'donut' | 'pqh9' | 'pharmasmart';

type GraphSliceType = {
  chart: ChartType | undefined;
  trends: Trend[];
  trendsApiStatus: APIStatus;
  stateFrequencyData: StatusTime[];
  stateFrequencyApiStatus: APIStatus;
  calendar: CalendarData[];
  calendarApiStatus: APIStatus;
  donutStartDate: Dayjs;
  donutEndDate: Dayjs;
  calendarStartDate: Dayjs;
  calendarEndDate: Dayjs;
};

const initialState: GraphSliceType = {
  chart: undefined,
  trends: [],
  trendsApiStatus: APIStatus.IDLE,
  stateFrequencyData: [],
  stateFrequencyApiStatus: APIStatus.IDLE,
  calendar: [],
  calendarApiStatus: APIStatus.IDLE,
  donutStartDate: dayjs().subtract(1, 'months'),
  donutEndDate: dayjs(),
  calendarStartDate: dayjs('2019-01'),
  calendarEndDate: dayjs(),
};

export const fetchTrends = createAsyncThunk<Trend[], { client_id: string; type: TrendType }, AsyncThunkConfig>(
  'graph/fetchTrends',
  async ({ client_id, type }, thunkAPI) => {
    try {
      const url =
        type === 'bam17'
          ? 'v2_bam_17_use_field_entries'
          : type === 'phq9'
            ? 'v3_phq_9_use_field_entries'
            : 'v0_pharmasmart_measurement_fields';
      const response = (await axios.post(url, { client_id })) as Trend[];
      return response ?? [];
    } catch (e) {
      logError(e, ['graphSlice', 'fetchTrends']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const fetchStateFrequency = createAsyncThunk<StatusTime[], string, AsyncThunkConfig>(
  'graph/fetchStateFrequency',
  async (client_id, thunkAPI) => {
    try {
      const { donutStartDate, donutEndDate } = thunkAPI.getState().graphSlice;
      const response = (await axios.post('v2_client_state_frequency', {
        client_id,
        start_date: donutStartDate,
        end_date: donutEndDate,
      })) as StatusTime[];
      return response ?? [];
    } catch (e) {
      logError(e, ['graphSlice', 'fetchStateFrequency']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const fetchCalendarHistory = createAsyncThunk<CalendarData[], string, AsyncThunkConfig>(
  'graph/fetchCalendarHistory',
  async (client_id, thunkAPI) => {
    try {
      const { calendarStartDate, calendarEndDate } = thunkAPI.getState().graphSlice;
      const response = (await axios.post('v2_client_risk_history', {
        client_id,
        start_date: calendarStartDate,
        end_date: calendarEndDate,
      })) as CalendarData[];
      return response ?? [];
    } catch (e) {
      logError(e, ['graphSlice', 'fetchCalendarHistory']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const graphSlice = createSlice({
  name: 'graph',
  initialState,
  reducers: {
    selectDates: (state, action: PayloadAction<{ startDate: Dayjs; endDate: Dayjs }>) => {
      const { startDate, endDate } = action.payload;
      state.donutStartDate = startDate;
      state.donutEndDate = endDate;
    },
    selectChart: (state, action: PayloadAction<ChartType | undefined>) => {
      state.chart = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTrends.pending, (state, _action) => {
        state.trendsApiStatus = APIStatus.PENDING;
      })
      .addCase(fetchTrends.fulfilled, (state, action) => {
        state.trends = action.payload;
        state.trendsApiStatus = APIStatus.FULFILLED;
      })
      .addCase(fetchStateFrequency.pending, (state, _action) => {
        state.stateFrequencyApiStatus = APIStatus.PENDING;
      })
      .addCase(fetchStateFrequency.fulfilled, (state, action) => {
        state.stateFrequencyData = action.payload;
        state.stateFrequencyApiStatus = APIStatus.FULFILLED;
      })
      .addCase(fetchCalendarHistory.pending, (state, _action) => {
        state.calendarApiStatus = APIStatus.PENDING;
      })
      .addCase(fetchCalendarHistory.fulfilled, (state, action) => {
        state.calendar = action.payload;
        state.calendarApiStatus = APIStatus.FULFILLED;
      });
  },
});

export const { selectDates, selectChart } = graphSlice.actions;
