import type { AxiosError } from 'axios';
import axios from 'axios';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import type { StatusKey } from '../../../../../enum';
import { APIStatus } from '../../../../../enum';
import type { Measurement, ScoreDetail } from '../../../../../models/client';
import type { ErrorMessage } from '../../../../../models/error';
import type { AsyncThunkConfig } from '../../../../../models/slice';
import type { FilteredInfo, RequestParams, TableChange } from '../../../../../models/table';
import { SortedInfo } from '../../../../../models/table';
import { RaygunErrorHandlerService } from '../../../../../service/raygun.service';
import { removeEmpty } from '../../../../../service/table.service';

const { logError } = RaygunErrorHandlerService();

export type ClientEntry = {
  id: string;
  callback: string;
  client_id: string;
  client_journal_id: string;
  clinician_message: string;
  clinician_name: string;
  created_date: string;
  details: string;
  diary: string;
  fields: Field[];
  journal_order: number;
  measurements: Measurement;
  mood: string;
  mood_id: string;
  product: string;
  score: number;
  score_details: ScoreDetail;
  sentiment: string;
  status: StatusKey;
  submission_time: string;
  total_count: number;
  type: string;
};

export type Field = {
  field_id: string;
  field_name: string;
  field_type: string;
  field_description: string;
  field_short_name?: string;
  selections: Selection[];
  colour_code?: string;
  remarks: string;
};

type Selection = {
  colour_code: string;
  icon: string;
  img: string;
  numeric_value: number;
  value: string;
};

type ClientEntriesSliceType = {
  clientEntries: ClientEntry[];
  clientEntriesApiStatus: APIStatus;
  requestClientEntriesParams: RequestParams<ClientEntry>;
};

const initialState: ClientEntriesSliceType = {
  clientEntries: [],
  clientEntriesApiStatus: APIStatus.IDLE,
  requestClientEntriesParams: {
    pagination: {
      current: 1,
      pageSize: 50,
      defaultPageSize: 50,
    },
    sortedInfo: new SortedInfo<ClientEntry>('submission_time', 'descend'),
    filteredInfo: {},
  },
};

export const fetchClientEntries = createAsyncThunk<ClientEntry[], string, AsyncThunkConfig>(
  'clientEntries/fetchClientEntries',
  async (client_id: string, thunkAPI) => {
    try {
      const {
        clientEntriesSlice: {
          requestClientEntriesParams: { pagination, sortedInfo, filteredInfo },
        },
      } = thunkAPI.getState();
      const response = await axios.post('v2_client_submission', {
        client_id,
        pagination,
        sortedInfo,
        filteredInfo,
      });
      return (response as unknown as ClientEntry[]) ?? [];
    } catch (e) {
      logError(e, ['clientEntriesSlice', 'fetchClientEntries']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const clientEntriesSlice = createSlice({
  name: 'clientEntries',
  initialState,
  reducers: {
    handleClientEntriesChange: (state, action: PayloadAction<TableChange<ClientEntry>>) => {
      const filters = action.payload.filters as FilteredInfo;
      const sorter = action.payload.sorter as SortedInfo<ClientEntry>;
      if (!sorter.order) {
        sorter.order = null;
      }
      state.requestClientEntriesParams.pagination = action.payload.pagination;
      state.requestClientEntriesParams.sortedInfo = sorter;
      state.requestClientEntriesParams.filteredInfo = removeEmpty(filters);
    },
    clearFilter: (state) => {
      state.requestClientEntriesParams.pagination = initialState.requestClientEntriesParams.pagination;
      state.requestClientEntriesParams.sortedInfo = initialState.requestClientEntriesParams.sortedInfo;
      state.requestClientEntriesParams.filteredInfo = initialState.requestClientEntriesParams.filteredInfo;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchClientEntries.pending, (state, _action) => {
        state.clientEntriesApiStatus = APIStatus.PENDING;
      })
      .addCase(fetchClientEntries.fulfilled, (state, action) => {
        state.clientEntries = action.payload;
        state.clientEntriesApiStatus = APIStatus.FULFILLED;
      })
      .addCase(fetchClientEntries.rejected, (state, _action) => {
        state.clientEntriesApiStatus = APIStatus.ERROR;
      });
  },
});

export const { handleClientEntriesChange, clearFilter } = clientEntriesSlice.actions;
