import type { AxiosError } from 'axios';
import axios from 'axios';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { APIStatus } from '../../../../enum';
import type { ErrorMessage } from '../../../../models/error';
import type { AsyncThunkConfig } from '../../../../models/slice';
import { RaygunErrorHandlerService } from '../../../../service/raygun.service';
import type { AdminClient } from '../adminClientSlice';

const { logError } = RaygunErrorHandlerService();

type ClientBulkActionSliceType = {
  clientBulkToAssign: ClientBulkAssign;
  showBulkAssignmentModal: boolean;
  clientBulkToFeedback: ClientBulkToFeedback;
  showBulkFeedbackModal: boolean;
  selectedClients: AdminClient[];
  notAssignedClients: AdminClient[];
  singleAssignmentClients: AdminClient[];
  alreadyAssignedClients: AdminClient[];
  loading: boolean;
  bulkAssignResponse: BulkAssignResponse;
  nextButtonDisabled: boolean;
  operation: Operation | undefined;
  invalidDevicesClients: AdminClient[];
  invalidDevicesClientsAPIStatus: string;
  bulkFeedbackAPIStatus: string;
};

type ClientBulkAssign = {
  client_ids: string[];
  site_id: string;
  assign: boolean;
  clinician_id: string;
};

class ClientBulkToFeedback {
  client_ids: string[] = [];
  custom_message: string = '';
  emoji_id: string = '';
}

class BulkAssignResponse {
  num_clients: number = 0;
  site_name: string = '';
  clinician_name: string = '';
}

export enum Operation {
  SITE = 'Site',
  CLINICIAN = 'Clinician',
}

const initialState: ClientBulkActionSliceType = {
  clientBulkToAssign: {
    client_ids: [],
    site_id: '',
    assign: false,
    clinician_id: '',
  },
  showBulkAssignmentModal: false,
  clientBulkToFeedback: new ClientBulkToFeedback(),
  showBulkFeedbackModal: false,
  selectedClients: [],
  notAssignedClients: [],
  singleAssignmentClients: [],
  alreadyAssignedClients: [],
  loading: false,
  bulkAssignResponse: new BulkAssignResponse(),
  nextButtonDisabled: true,
  operation: undefined,
  invalidDevicesClients: [],
  invalidDevicesClientsAPIStatus: APIStatus.IDLE,
  bulkFeedbackAPIStatus: APIStatus.IDLE,
};

export const clientBulkAssign = createAsyncThunk<BulkAssignResponse, undefined, AsyncThunkConfig>(
  'clientBulkAction/clientBulkAssign',
  async (_, thunkAPI) => {
    try {
      const clientBulkActionSlice = thunkAPI.getState().clientBulkActionSlice;
      const endpoint =
        clientBulkActionSlice.operation === Operation.CLINICIAN
          ? 'v3_client_bulk_clinician_assignment'
          : 'v3_client_bulk_site_assignment';
      const data = { ...clientBulkActionSlice.clientBulkToAssign };
      if (clientBulkActionSlice.operation === Operation.CLINICIAN) {
        // @ts-ignore
        delete data.site_id;
      } else {
        // @ts-ignore
        delete data.clinician_id;
      }

      const response = await axios.post(endpoint, data);
      return response as unknown as BulkAssignResponse;
    } catch (e) {
      logError(e, ['clientBulkActionSlice', 'clientBulkAssign']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const clientBulkFeedback = createAsyncThunk<BulkAssignResponse, undefined, AsyncThunkConfig>(
  'clientBulkAction/clientBulkFeedback',
  async (_, thunkAPI) => {
    try {
      const clientBulkToFeedback = thunkAPI.getState().clientBulkActionSlice.clientBulkToFeedback;
      const endpoint = 'v3_client_bulk_feedback';
      const response = await axios.post(endpoint, clientBulkToFeedback);
      return response as unknown as BulkAssignResponse;
    } catch (e) {
      logError(e, ['clientBulkActionSlice', 'clientBulkFeedback']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const fetchBulkDeviceStatus = createAsyncThunk<string[], undefined, AsyncThunkConfig>(
  'clientBulkAction/fetchBulkDeviceStatus',
  async (_, thunkAPI) => {
    try {
      const client_ids = thunkAPI.getState().clientBulkActionSlice.clientBulkToFeedback.client_ids;
      const response = await axios.post('v3_client_bulk_valid_device_status', { client_ids });
      return (response as unknown as string[]) ?? [];
    } catch (e) {
      logError(e, ['clientBulkActionSlice', 'fetchBulkDeviceStatus']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const clientBulkActionSlice = createSlice({
  name: 'clientBulkAction',
  initialState,
  reducers: {
    setClientIds: (state, action: PayloadAction<string[]>) => {
      state.clientBulkToAssign.client_ids = action.payload;
      state.clientBulkToFeedback.client_ids = action.payload;
    },
    setAssign: (state, action: PayloadAction<boolean>) => {
      state.clientBulkToAssign.assign = action.payload;
      state.showBulkAssignmentModal = true;
    },

    setOperation: (state, action: PayloadAction<Operation>) => {
      state.operation = action.payload;
      state.nextButtonDisabled = false;
    },
    setSiteId: (state, action: PayloadAction<string>) => {
      if (state.operation === Operation.CLINICIAN) {
        state.clientBulkToAssign.clinician_id = action.payload;
      } else {
        state.clientBulkToAssign.site_id = action.payload;
      }
      state.nextButtonDisabled = false;
    },
    setSelectedClients: (state, action: PayloadAction<AdminClient[]>) => {
      state.selectedClients = action.payload;
    },
    setBulkActionCancel: (state) => {
      state.showBulkAssignmentModal = false;
      state.clientBulkToAssign.assign = false;
      state.clientBulkToAssign.site_id = '';
      state.clientBulkToAssign.clinician_id = '';
      state.operation = undefined;
      state.nextButtonDisabled = true;
    },
    setBulkFeedbackCancel: (state) => {
      state.showBulkFeedbackModal = false;
      state.clientBulkToFeedback.custom_message = '';
      state.clientBulkToFeedback.emoji_id = '';
      state.nextButtonDisabled = true;
    },
    clearRowSelection: (state) => {
      state.showBulkAssignmentModal = false;
      state.clientBulkToAssign = {
        client_ids: [],
        site_id: '',
        assign: false,
        clinician_id: '',
      };
      state.operation = undefined;
      state.nextButtonDisabled = true;
    },
    setNextButtonDisabled: (state, action: PayloadAction<boolean>) => {
      state.nextButtonDisabled = action.payload;
    },
    getStep3SiteData: (state) => {
      if (state.clientBulkToAssign.site_id) {
        state.notAssignedClients = state.selectedClients.filter((client) => {
          if (!client) return false;
          if (client.site_ids && client.site_ids.length > 0) {
            return client.site_ids.every((item) => item !== state.clientBulkToAssign.site_id);
          }
          return true;
        });

        state.singleAssignmentClients = state.selectedClients.filter((client) => {
          if (client && client.site_ids && client.site_ids.length === 1) {
            return client.site_ids[0] === state.clientBulkToAssign.site_id;
          }
          return false;
        });

        state.alreadyAssignedClients = state.selectedClients.filter((client) => {
          if (client && client.site_ids && client.site_ids.length > 0) {
            return client.site_ids?.includes(state.clientBulkToAssign.site_id);
          }
          return false;
        });
      }
    },
    getStep3ClinicianData: (state) => {
      if (state.clientBulkToAssign.clinician_id) {
        state.notAssignedClients = state.selectedClients.filter((client) => {
          if (!client) return false;
          if (client.clinician_ids && client.clinician_ids.length > 0) {
            return client.clinician_ids.every((item) => item !== state.clientBulkToAssign.clinician_id);
          }
          return true;
        });

        state.singleAssignmentClients = state.selectedClients.filter((client) => {
          if (client && client.clinician_ids && client.clinician_ids.length === 1) {
            return client.clinician_ids[0] === state.clientBulkToAssign.clinician_id;
          }
          return false;
        });

        state.alreadyAssignedClients = state.selectedClients.filter((client) => {
          if (client && client.clinician_ids && client.clinician_ids.length > 0) {
            return client.clinician_ids?.includes(state.clientBulkToAssign.clinician_id);
          }
          return false;
        });
      }
    },
    showBulkFeedback: (state, _action) => {
      state.showBulkFeedbackModal = true;
    },
    setFeedbackEmojiID: (state, action: PayloadAction<string>) => {
      state.clientBulkToFeedback.emoji_id = action.payload;
      state.nextButtonDisabled = false;
    },
    setFeedbackCustomMessage: (state, action: PayloadAction<string>) => {
      state.clientBulkToFeedback.custom_message = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(clientBulkAssign.pending, (state, _action) => {
        state.loading = true;
      })
      .addCase(clientBulkAssign.fulfilled, (state, action) => {
        state.loading = false;
        state.bulkAssignResponse = action.payload;
      })
      .addCase(clientBulkAssign.rejected, (state, _action) => {
        state.loading = false;
      })
      .addCase(fetchBulkDeviceStatus.pending, (state, _action) => {
        state.invalidDevicesClientsAPIStatus = APIStatus.PENDING;
      })
      .addCase(fetchBulkDeviceStatus.fulfilled, (state, action) => {
        state.invalidDevicesClientsAPIStatus = APIStatus.FULFILLED;
        const validDeviceStatusClientIds = action.payload || [];
        state.invalidDevicesClients = state.selectedClients.filter(
          (client) => !validDeviceStatusClientIds.includes(client.id ?? ''),
        );
      })
      .addCase(fetchBulkDeviceStatus.rejected, (state, _action) => {
        state.invalidDevicesClientsAPIStatus = APIStatus.ERROR;
      })
      .addCase(clientBulkFeedback.pending, (state, _action) => {
        state.bulkFeedbackAPIStatus = APIStatus.PENDING;
      })
      .addCase(clientBulkFeedback.fulfilled, (state, action) => {
        state.bulkFeedbackAPIStatus = APIStatus.FULFILLED;
        state.bulkAssignResponse = action.payload;
      })
      .addCase(clientBulkFeedback.rejected, (state, _action) => {
        state.bulkFeedbackAPIStatus = APIStatus.ERROR;
      });
  },
});

export const {
  setClientIds,
  setAssign,
  setSiteId,
  setSelectedClients,
  getStep3SiteData,
  getStep3ClinicianData,
  setBulkActionCancel,
  setBulkFeedbackCancel,
  clearRowSelection,
  setOperation,
  setNextButtonDisabled,
  showBulkFeedback,
  setFeedbackEmojiID,
  setFeedbackCustomMessage,
} = clientBulkActionSlice.actions;
