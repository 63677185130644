import type { LabeledValue, SelectValue } from 'antd/lib/select';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';

import type { ReportData } from './adminReportSlice';

export const reportDatesRange: LabeledValue[] = [
  {
    label: '3 days',
    value: '3 days',
  },
  {
    label: '1 week',
    value: '1 weeks',
  },
  {
    label: '2 weeks',
    value: '2 weeks',
  },
  {
    label: '1 month',
    value: '1 months',
  },
  {
    label: '3 months',
    value: '3 months',
  },
  {
    label: '6 months',
    value: '6 months',
  },
  {
    label: 'All data',
    value: '6 years',
  },
];

export const getDateRangeValue = (
  startDate: Dayjs = dayjs(),
  endDate: Dayjs = dayjs(),
  datesRange: LabeledValue[],
): SelectValue => {
  let result = 'Custom' as SelectValue;

  for (const { value } of datesRange) {
    const [amount, unit] = (value as string).split(' ');
    const endDateToCompare = dayjs(startDate).add(Number(amount), unit as dayjs.ManipulateType);

    if (endDate.isSame(endDateToCompare, 'day')) {
      result = value;
      break;
    }
  }

  return result;
};

export const combineResponses = (response1: ReportData[], response2: ReportData[]) => {
  let result: ReportData[] = [];

  if (response1 && response2) {
    const combined: ReportData[] = [];
    const totalLength = response1.length;

    for (let l = 0; l < totalLength; ++l) {
      const type1 = response1[l].type;
      const type2 = response2[l].type;
      const data1 = response1[l].data as number[];
      const data2 = response2[l].data as number[];
      const name1 = response1[l].name;
      const name2 = response2[l].name;

      if (type1 === type2 && name1 === name2 && (type1 !== 'category' || type2 !== 'category')) {
        const sum = data1.map((a, i) => a + data2[i]);
        combined.push({
          type: type1,
          name: name1,
          data: sum,
        });
      } else {
        combined.push({
          type: type1,
          name: name1,
          data: data1,
        });
      }
    }
    result = combined;
  } else if (response1) {
    result = response1;
  }

  return result;
};

export const getPercentageFromRate = (rate: number | null) => `${((rate || 0) * 100).toFixed()}%`;

export const combineResponseAsCSV = (response1: string, response2: string) => {
  let result = '';

  if (response1 && response2) {
    const env1 = response1;
    const env2 = response2;
    const lines1 = env1.split('\n');
    const lines2 = env2.split('\n');
    const lines = lines1.length;
    const cols = lines1[0].split(',').length;
    result += lines1[0];
    for (let l = 1; l < lines; ++l) {
      const line1 = lines1[l].split(',');
      const line2 = lines2[l].split(',');
      if (line1.length !== line2.length) {
        throw new Error('Responses from different environments are incompatible.');
      }
      if (line1.length > 1) {
        result += `\n${line1[0]}`;
        for (let c = 1; c < cols; ++c) {
          const item1 = line1[c];
          const item2 = line2[c];
          if (!Number.isNaN(item1) && !Number.isNaN(item2)) {
            const sum = parseInt(item1) + parseInt(item2);
            result += `,${sum}`;
          } else {
            result += `,${item1}`;
          }
        }
      }
    }
  } else if (response1) {
    result = response1 as unknown as string;
  }
  return result;
};
