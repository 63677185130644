import { notification } from 'antd';
import type { AxiosError, AxiosResponse } from 'axios';
import axios from 'axios';

import { CheckCircleOutlined } from '@ant-design/icons';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import type { UserRole } from '../../../enum';
import { APIStatus } from '../../../enum';
import { environment } from '../../../environment/environment';
import type { ErrorMessage } from '../../../models/error';
import type { AsyncThunkConfig } from '../../../models/slice';
import { RaygunErrorHandlerService } from '../../../service/raygun.service';
import { getCurrentUser, getRememberedEmail, saveRememberedEmail } from './login.helper';

const { setRaygunUser, resetRaygunUser, logError } = RaygunErrorHandlerService();

export class AuthResult {
  country: string | null = null;
  full_name: string | null = null;
  token: string | null = null;
  use_actions: boolean = false;
  use_badge: boolean = false;
  use_bam_17_trend_graph: boolean = false;
  use_phq_9_trend_graph: boolean = false;
  use_client_journal_selection: boolean = false;
  use_cravings_quick_checkin: boolean = false;
  use_indicator_graph_card: boolean = false;
  use_location: boolean = false;
  use_mood: boolean = false;
  use_multi_product_clients: boolean = false;
  use_risk_calendar_graph: boolean = false;
  use_status_time_graph: boolean = false;
  use_risk_indicator_graph: boolean = false;
  use_score_column: boolean = false;
  use_sentiment: boolean = false;
  use_si: boolean = false;
  use_sms: boolean = false;
  use_streak_component: boolean = false;
  use_streak_component_title: boolean = false;
  use_text_entry: boolean = false;
  use_insurance_carrier: boolean = false;
  use_apa_features: boolean = false;
  use_risk_nomenclature: boolean = false;
  use_callback: boolean = false;
  use_client_program: boolean = false;
  use_client_type: boolean = false;
  use_clinician_dashboard: boolean = false;
  use_always_available_products: boolean = false;
  use_scheduler: boolean = false;
  use_qr_code: boolean = false;
}

export type Login = {
  email: string;
  password: string;
};

export class CurrentUser {
  role: UserRole | null = null;
  user_id: string | null = null;
  email: string | null = null;
  organization_id: string | null = null;
  exp: number | null = null;
  region: string | null = null;
}

type AuthSliceType = {
  authResult: AuthResult;
  authResult2: AuthResult;
  loginApiStatus: APIStatus;
  currentUser: CurrentUser;
  forgotPasswordApiSatus: APIStatus;
};

export const initialState: AuthSliceType = {
  authResult: new AuthResult(),
  authResult2: new AuthResult(),
  loginApiStatus: APIStatus.IDLE,
  currentUser: new CurrentUser(),
  forgotPasswordApiSatus: APIStatus.IDLE,
};

export const login = createAsyncThunk<AuthResult, Login, AsyncThunkConfig>('auth/login', async (login, thunkAPI) => {
  try {
    const response = (await axios.post('v2_login', login)) as [AuthResult];
    return response?.[0] ?? new AuthResult();
  } catch (e) {
    logError(e, ['authSlice', 'login']);
    return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
  }
});

export const login2 = createAsyncThunk<AuthResult, Login, AsyncThunkConfig>(
  'auth/login2',
  async (login: Login, thunkAPI) => {
    try {
      const response = (await axios.post('v2_login', login, { baseURL: environment.secondEndpoint })) as [AuthResult];
      return response?.[0] ?? new AuthResult();
    } catch (e) {
      logError(e, ['authSlice', 'login2']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const refreshToken = createAsyncThunk<AuthResult, undefined, AsyncThunkConfig>(
  'auth/refreshToken',
  async (_, thunkAPI) => {
    try {
      const response = (await axios.post('v2_token_refresh')) as [AuthResult];
      if (response?.[0].token) {
        return response[0];
      } else {
        notification.error({
          message: 'Token expired',
          description: 'Please sign in again.',
        });
        thunkAPI.dispatch(logout());
        return thunkAPI.rejectWithValue((response as unknown as AxiosResponse<ErrorMessage>).data);
      }
    } catch (e) {
      logError(e, ['authSlice', 'refreshToken']);
      thunkAPI.dispatch(logout());
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export type ForgotPasswordResponse = {
  success: boolean;
  message: string;
};

export const sendForgotPasswordEmail = createAsyncThunk<ForgotPasswordResponse, string, AsyncThunkConfig>(
  'auth/sendForgotPasswordEmail',
  async (email: string, thunkAPI) => {
    try {
      const response = await axios.post('forgot_password_admin', { email });
      notification.destroy();
      notification.success({
        message: 'Success',
        description: 'Email sent to ' + email,
        icon: <CheckCircleOutlined />,
      });
      return response as unknown as ForgotPasswordResponse;
    } catch (e) {
      logError(e, ['authSlice', 'sendForgotPasswordEmail']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const logout = createAsyncThunk<undefined, undefined, AsyncThunkConfig>('auth/logout', async (_, thunkAPI) => {
  try {
    resetRaygunUser();
    const rememberedEmail = await getRememberedEmail();
    localStorage.clear();
    if (rememberedEmail) {
      await saveRememberedEmail(rememberedEmail);
    }
    thunkAPI.dispatch({ type: 'LOG_OUT' });
  } catch (e) {
    logError(e, ['authSlice', 'logout']);
    return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
  }
});

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    restoreAuthState: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state, _action) => {
        state.loginApiStatus = APIStatus.PENDING;
      })
      .addCase(login.fulfilled, (state, action) => {
        const { token } = action.payload;
        if (token) {
          state.authResult = action.payload;
          state.loginApiStatus = APIStatus.FULFILLED;
          state.currentUser = getCurrentUser(token);
          setRaygunUser({
            organization_id: state.currentUser.organization_id,
            user_id: state.currentUser.user_id,
            role: state.currentUser.role,
          });
        } else {
          state.authResult = new AuthResult();
          state.loginApiStatus = APIStatus.ERROR;
        }
      })
      .addCase(login.rejected, (state, _action) => {
        state.loginApiStatus = APIStatus.ERROR;
      })
      .addCase(login2.pending, (state, _action) => {
        state.loginApiStatus = APIStatus.PENDING;
      })
      .addCase(login2.fulfilled, (state, action) => {
        const { token } = action.payload;
        if (token) {
          state.authResult2 = action.payload;
          state.loginApiStatus = APIStatus.FULFILLED;
        } else {
          state.authResult2 = new AuthResult();
          state.loginApiStatus = APIStatus.ERROR;
        }
      })
      .addCase(login2.rejected, (state, _action) => {
        state.loginApiStatus = APIStatus.ERROR;
      })
      .addCase(refreshToken.fulfilled, (state, action) => {
        const { token } = action.payload;
        if (token) {
          state.authResult = action.payload;
          state.currentUser = getCurrentUser(token);
          setRaygunUser({
            organization_id: state.currentUser.organization_id,
            user_id: state.currentUser.user_id,
            role: state.currentUser.role,
          });
        } else {
          state.authResult = new AuthResult();
        }
      })
      .addCase(sendForgotPasswordEmail.pending, (state, _action) => {
        state.forgotPasswordApiSatus = APIStatus.PENDING;
      })
      .addCase(sendForgotPasswordEmail.fulfilled, (state, _action) => {
        state.forgotPasswordApiSatus = APIStatus.FULFILLED;
      })
      .addCase(sendForgotPasswordEmail.rejected, (state, _action) => {
        state.forgotPasswordApiSatus = APIStatus.ERROR;
      });
  },
});

export const { restoreAuthState } = authSlice.actions;
