import { useEffect } from 'react';
import { usePostHog } from 'posthog-js/react';
import type { ReactNode } from 'react';
import { useLocation, useParams } from 'react-router';

const getPage = (pathname: string) => {
  const parts = pathname.split('/');
  return parts.includes('page') ? parts[2] : (parts[1] ?? parts[0]);
};

const CapturePostHogPageView = ({ children }: { children: ReactNode }) => {
  const location = useLocation();
  const posthog = usePostHog();
  const page = getPage(location.pathname);
  const { client_id } = useParams<{ client_id: string }>();

  useEffect(() => {
    const clientId = client_id ? { client_id } : undefined;

    posthog?.capture(`Entered ${page} page`, clientId);
    return () => {
      posthog?.capture(`Left ${page} page`, clientId);
    };
  }, [page, client_id, posthog]);

  return children;
};

export default CapturePostHogPageView;
