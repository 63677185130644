import { notification } from 'antd';
import type { AxiosError } from 'axios';
import axios from 'axios';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { APIStatus } from '../../../enum';
import type { ErrorMessage } from '../../../models/error';
import type { AsyncThunkConfig } from '../../../models/slice';
import { RaygunErrorHandlerService } from '../../../service/raygun.service';

const { logError } = RaygunErrorHandlerService();

export type ResetPasswordSliceType = {
  tokenFromPath: string | null;
  resetPasswordApiStatus: APIStatus;
};

const initialState: ResetPasswordSliceType = {
  tokenFromPath: null,
  resetPasswordApiStatus: APIStatus.IDLE,
};

export const checkToken = createAsyncThunk<boolean, undefined, AsyncThunkConfig>(
  'resetPassword/checkToken',
  async (_, thunkAPI) => {
    try {
      const { resetPasswordSlice } = thunkAPI.getState();
      const response = await axios.post(
        'v2_token_verify',
        {},
        {
          headers: {
            Authorization: `Bearer ${resetPasswordSlice.tokenFromPath}`,
          },
        },
      );
      return response as unknown as boolean;
    } catch (e) {
      logError(e, ['resetPasswordSlice', 'checkToken']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const sendPassword = createAsyncThunk<string, string, AsyncThunkConfig>(
  'resetPassword/sendPassword',
  async (password, thunkAPI) => {
    try {
      const { resetPasswordSlice } = thunkAPI.getState();
      const response = await axios.post(
        'v2_password_reset',
        { password },
        {
          headers: {
            Authorization: `Bearer ${resetPasswordSlice.tokenFromPath}`,
          },
        },
      );
      return response as unknown as string;
    } catch (e) {
      logError(e, ['resetPasswordSlice', 'sendPassword']);
      notification.error({
        message: 'Error',
        description: 'Password not updated.',
      });
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const resetPasswordSlice = createSlice({
  name: 'resetPassword',
  initialState,
  reducers: {
    updateTokenFromPath: (state, action: PayloadAction<string | null>) => {
      state.tokenFromPath = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendPassword.pending, (state, _action) => {
        state.resetPasswordApiStatus = APIStatus.PENDING;
      })
      .addCase(sendPassword.fulfilled, (state, _action) => {
        state.resetPasswordApiStatus = APIStatus.FULFILLED;
      })
      .addCase(sendPassword.rejected, (state, _action) => {
        state.resetPasswordApiStatus = APIStatus.ERROR;
      });
  },
});

export const { updateTokenFromPath } = resetPasswordSlice.actions;
