import React from 'react';
import { Button, Layout, Row } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import Link from 'antd/lib/typography/Link';
import type { ErrorInfo, ReactNode } from 'react';

import Logo from '../src/assets/tetherall_logo_full_1200.png';

import { logout } from './components/pages/Login/authSlice';
import type { AppDispatch } from './models/slice';
import { RaygunErrorHandlerService } from './service/raygun.service';

const { logError } = RaygunErrorHandlerService();

type Props = {
  children?: ReactNode;
  dispatch: AppDispatch;
};

type State = {
  hasError: boolean;
};

export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
    this.goBackLogin = this.goBackLogin.bind(this);
  }

  static getDerivedStateFromError(_: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    logError(error, ['ErrorBoundary', errorInfo.componentStack?.split(' ')[1] || '']); // like: "in ComponentName ..."
  }

  goBackLogin() {
    this.setState({ hasError: false });
    this.props.dispatch(logout());
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Layout>
          <Content className="loginPage">
            <div className="loginCard">
              <div className="loginForm">
                <img src={Logo} className="loginLogo" alt="TetherAll" />
                <Row className="ErrorHeader">
                  <b>Oops something went wrong.</b>
                  <span style={{ marginTop: 10, marginBottom: 20 }}>Please try again in a few minutes.</span>
                </Row>
                <Row>
                  <Button type="primary" onClick={this.goBackLogin}>
                    Logout
                  </Button>
                </Row>
                <br />
                <span>
                  Having trouble?{' '}
                  <Link underline href="mailto:support@trycycle.ca">
                    Contact us
                  </Link>
                </span>
              </div>
            </div>
          </Content>
        </Layout>
      );
    }

    return this.props.children;
  }
}
