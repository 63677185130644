import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { FilteredInfo, RequestParams, TableChange } from '../../../../models/table';
import { SortedInfo } from '../../../../models/table';
import { removeEmpty } from '../../../../service/table.service';

export type ClientEngagementDetail = {
  client_id: string;
  client_name: string;
  sites: string[];
  products: string[];
  weeks_with_journals: number;
  total_weeks: number;
};

export type CheckIn = {
  client_id: string;
  full_name: string;
  sites: string[];
  products: string[];
  total: number;
};

export type TotalClientDetail = {
  sites: string[];
  products: string[];
  account_status: string;
  created: string;
  email: string;
  full_name: string;
};

export type NewClient = {
  full_name: string;
  email: string;
  sites: string[];
  products: string[];
  created: string;
  account_status: string;
};

export type ClientNoNotification = {
  full_name: string;
  email: string;
  last_check_in: string;
  sites: string[];
};

export type ClientNotOnboarded = {
  full_name: string;
  email: string;
  created_date: string;
  sites: string[];
};

export type DashboardModalType =
  | ClientEngagementDetail
  | CheckIn
  | TotalClientDetail
  | NewClient
  | ClientNoNotification
  | ClientNotOnboarded;

export enum MODAL {
  IDLE = 'idle',
  CLIENT_ENGAGEMENT = 'client_engagement',
  CHECKIN_DAY_AND_TIME = 'checkin_day_and_time',
  PRODUCT_TYPE = 'product_type',
  AGE_DISTRIBUTION = 'age_distribution',
  PRIMARY_DIAGNOSIS = 'primary_diagnosis',
  GENDER = 'gender',
  NO_ACTIVITY = 'no_activity',
  NO_NOTIFICATIONS = 'no_notifications',
  NEW_CLIENTS = 'new_clients',
  NOT_ONBOARDED = 'not_onboarded',
  CHECK_INS = 'check_ins',
  FEELINGS = 'feelings count',
  TOTAL_CLIENT = 'total_client_details',
  SENTIMENT = 'sentiment',
  TIME_IN_RISK = 'time_in_risk',
  INTERVENTIONS = 'interventions',
  MOST_COMMON_JOURNAL_WORDS = 'most_common_journal_words',
}

type ModalDashboardStore = {
  requestModalParam: RequestParams<DashboardModalType>;
  modal: MODAL;
};

const initialState: ModalDashboardStore = {
  requestModalParam: {
    pagination: {
      current: 1,
      pageSize: 50,
      defaultPageSize: 50,
    },
    sortedInfo: new SortedInfo<DashboardModalType>('sites', 'descend'),
    filteredInfo: {},
  },
  modal: MODAL.IDLE,
};

export const modalDashboardSlice = createSlice({
  name: 'modalDashboard',
  initialState,
  reducers: {
    showDashboardModal: (state, action: PayloadAction<MODAL>) => {
      state.modal = action.payload;
    },
    closeDashboardModal: (state) => {
      state.modal = MODAL.IDLE;
    },
    handleModalTableChange: (state, action: PayloadAction<Partial<TableChange<DashboardModalType>>>) => {
      const filters = action.payload.filters as FilteredInfo;
      const sorter = action.payload.sorter as SortedInfo<DashboardModalType>;
      if (!sorter.order) {
        sorter.order = null;
      }
      state.requestModalParam.pagination = action.payload.pagination ?? initialState.requestModalParam.pagination;
      state.requestModalParam.sortedInfo = sorter ?? initialState.requestModalParam.sortedInfo;
      state.requestModalParam.filteredInfo = removeEmpty(filters);
    },
    clearModalFilter: (state) => {
      state.requestModalParam = initialState.requestModalParam;
    },
  },
  extraReducers: (_builder) => {},
});

export const { showDashboardModal, closeDashboardModal, handleModalTableChange, clearModalFilter } =
  modalDashboardSlice.actions;
