import type { AxiosError } from 'axios';
import axios from 'axios';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { APIStatus } from '../../../enum';
import type { ErrorMessage } from '../../../models/error';
import type { AsyncThunkConfig } from '../../../models/slice';
import type {
  CheckIn,
  ClientEngagementDetail,
  ClientNoNotification,
  ClientNotOnboarded,
  NewClient,
  TotalClientDetail,
} from '../program-dashboard/modals/modalDashboardSlice';
import type { Intervention } from '../program-dashboard/programDashboardSlice';

export type Clinician = {
  id: string;
  full_name: string;
};

export type DateCount = { date: string; count: number };

export type DashboardRequestBody = {
  end_date: Dayjs;
  start_date: Dayjs;
  clinician_id?: string;
  diagnosis_id?: string;
};

export type Interval = Record<string, number>;

export type ClinicianDashboardData = {
  end_date?: Dayjs;
  start_date?: Dayjs;
  clinician_id?: string;
  client_engagement: number | null;
  client_engagement_details: ClientEngagementDetail[];
  check_ins: CheckIn[] | null;
  total_clients_details: TotalClientDetail[] | null;
  new_clients: NewClient[] | null;
  new_clients_first_submission_average_time: Interval | null;
  did_onboarding_rate: number | null;
  issues: {
    number_of_clients_with_no_activity: number | null;
    clients_with_no_devices: ClientNoNotification[] | null;
    clients_who_did_not_onboard: ClientNotOnboarded[] | null;
  };
  clinician_activity_trends: {
    interventions_details: Intervention[];
    logins: DateCount[] | null;
    feedback: DateCount[] | null;
    interventions: DateCount[] | null;
  };
};

export type ClinicianDashboardStore = {
  clinicianDashboardParams: DashboardRequestBody;
  clinicianDashboardData: ClinicianDashboardData;
  apiStatus: string;
};

const initialState: ClinicianDashboardStore = {
  clinicianDashboardParams: {
    start_date: dayjs().subtract(1, 'month'),
    end_date: dayjs(),
  },
  clinicianDashboardData: {
    client_engagement: null,
    client_engagement_details: [],
    check_ins: null,
    total_clients_details: null,
    new_clients: null,
    new_clients_first_submission_average_time: null,
    did_onboarding_rate: null,
    issues: {
      number_of_clients_with_no_activity: null,
      clients_with_no_devices: null,
      clients_who_did_not_onboard: null,
    },
    clinician_activity_trends: {
      interventions_details: [],
      logins: null,
      feedback: null,
      interventions: null,
    },
  },
  apiStatus: APIStatus.IDLE,
};

export const loadDashboard = createAsyncThunk<ClinicianDashboardData, undefined, AsyncThunkConfig>(
  'clinicianDashboard/loadDashboard',
  async (_, thunkAPI) => {
    try {
      const { clinicianDashboardSlice } = thunkAPI.getState();
      const response = await axios.post('v3_clinician_dashboard', clinicianDashboardSlice.clinicianDashboardParams);
      return response as unknown as ClinicianDashboardData;
    } catch (e) {
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const clinicianDashboardSlice = createSlice({
  name: 'clinicianDashboard',
  initialState,
  reducers: {
    handleParamsChange: (state, action: PayloadAction<Partial<DashboardRequestBody>>) => {
      Object.assign(state.clinicianDashboardParams, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadDashboard.pending, (state) => {
        state.apiStatus = APIStatus.PENDING;
      })
      .addCase(loadDashboard.fulfilled, (state, action) => {
        state.clinicianDashboardData = action.payload;
        state.clinicianDashboardData.clinician_id = state.clinicianDashboardParams.clinician_id;
        state.clinicianDashboardData.start_date = state.clinicianDashboardParams.start_date;
        state.clinicianDashboardData.end_date = state.clinicianDashboardParams.end_date;
        state.apiStatus = APIStatus.FULFILLED;
      })
      .addCase(loadDashboard.rejected, (state) => {
        state.apiStatus = APIStatus.ERROR;
      });
  },
});

export const { handleParamsChange } = clinicianDashboardSlice.actions;
