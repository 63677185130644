import { Suspense } from 'react';
import { Spin } from 'antd';
import type { ReactNode } from 'react';

import { ErrorBoundary } from '../errorBoundary';
import type { AppDispatch } from '../models/slice';

export const SuspenseRoute = ({ dispatch, children }: { dispatch: AppDispatch; children: ReactNode }) => {
  return (
    <ErrorBoundary dispatch={dispatch}>
      <Suspense fallback={<Spin style={{ marginTop: '25%' }} />}>{children}</Suspense>
    </ErrorBoundary>
  );
};
