import { Button } from 'antd';
import { Link } from 'react-router';

import { MenuUnfoldOutlined } from '@ant-design/icons';

import { useAppSelector } from '../../hooks';
import { redirectPage } from '../../routes/routes';
import { SideNavMenu } from './sidemenu';

export const SideNav = ({ collapsed, handleToggle }: { collapsed: boolean; handleToggle: () => void }) => {
  const currentUser = useAppSelector((state) => state.authSlice.currentUser);

  return (
    <div>
      <Button className="sideMenuBtn" onClick={handleToggle}>
        <MenuUnfoldOutlined />
      </Button>

      <Link to={redirectPage(currentUser)}>
        <img
          src={`assets/${collapsed ? 'icon' : 'logo'}.png`}
          alt="TetherAll"
          style={{
            width: 'auto',
            display: 'block',
            margin: '20px auto',
            marginTop: -5,
            height: 32,
          }}
        />
      </Link>

      <SideNavMenu />
    </div>
  );
};
