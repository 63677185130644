import { Layout } from 'antd';

import { environment } from '../../environment/environment';

const { Footer } = Layout;
const year = new Date().getFullYear();

export const FooterComponent = () => {
  return (
    <Footer style={{ padding: '12px 0' }}>
      <span>
        Copyright © {year} TryCycle Data Systems Inc. All rights reserved. Version: {environment.versionNumber}.
        {environment.buildNumber}
      </span>
    </Footer>
  );
};
