import { notification } from 'antd';

import type { AdminSite } from './adminSitesSlice';

export const validateAdminSite = (adminSite: AdminSite) => {
  const phoneRegex = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
  const emailRegex = /^[^@]+@[^@]+\.\w\w+$/g;

  if (
    !adminSite.name ||
    !adminSite.city ||
    !adminSite.address ||
    !adminSite.country ||
    !adminSite.state ||
    !adminSite.postal_code ||
    !adminSite.phone ||
    !adminSite.email
  ) {
    notification.error({
      message: 'Missing required fields',
      description: 'Please provide values for all required fields.',
    });
    return false;
  } else if (!adminSite.email.match(emailRegex)) {
    notification.error({
      message: 'Invalid email',
      description: 'Please provide a valid email.',
    });
    return false;
  } else if (adminSite.phone && !adminSite.phone.match(phoneRegex)) {
    notification.error({
      message: 'Invalid phone',
      description: 'Please provide a valid phone number.',
    });
    return false;
  } else {
    return true;
  }
};
