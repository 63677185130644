import type { AxiosError } from 'axios';
import axios from 'axios';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { APIStatus } from '../../../enum';
import type { ErrorMessage } from '../../../models/error';
import type { AsyncThunkConfig } from '../../../models/slice';
import type { FilteredInfo, RequestParams, TableChange } from '../../../models/table';
import { SortedInfo } from '../../../models/table';
import { RaygunErrorHandlerService } from '../../../service/raygun.service';
import { removeEmpty } from '../../../service/table.service';

const { logError } = RaygunErrorHandlerService();

export type Intervention = {
  client_full_name: string;
  clinician_full_name: string;
  details: string;
  organization: string;
  organization_id: string;
  severity: string;
  sites: string;
  timestamp: string;
  total_count: number;
};

type InterventionSliceType = {
  interventionData: Intervention[];
  interventionApiStatus: APIStatus;
  requestInterventionParams: RequestParams<Intervention>;
  interventionAPIErrorText: string;
};

const initialState: InterventionSliceType = {
  interventionData: [],
  interventionAPIErrorText: '',
  interventionApiStatus: APIStatus.IDLE,
  requestInterventionParams: {
    pagination: {
      current: 1,
      pageSize: 50,
      defaultPageSize: 50,
    },
    sortedInfo: new SortedInfo<Intervention>('timestamp', 'descend'),
    filteredInfo: {},
  },
};

export const fetchAllInterventions = createAsyncThunk<Intervention[], undefined, AsyncThunkConfig>(
  'intervention/fetchAllInterventions',
  async (_, thunkAPI) => {
    try {
      const { interventionSlice } = thunkAPI.getState();
      const response = (await axios.post(
        'v3_intervention',
        interventionSlice.requestInterventionParams,
      )) as Intervention[];
      return response ?? [];
    } catch (e) {
      logError(e, ['interventionSlice', 'fetchAllInterventions']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const interventionSlice = createSlice({
  name: 'intervention',
  initialState,
  reducers: {
    handleInterventionChange: (state, action: PayloadAction<TableChange<Intervention>>) => {
      const filters = action.payload.filters as FilteredInfo;
      const sorter = action.payload.sorter as SortedInfo<Intervention>;
      if (!sorter.order) {
        sorter.order = null;
      }
      state.requestInterventionParams.pagination = action.payload.pagination;
      state.requestInterventionParams.sortedInfo = sorter;
      state.requestInterventionParams.filteredInfo = removeEmpty(filters);
    },
    clearFilter: (state) => {
      state.requestInterventionParams = initialState.requestInterventionParams;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllInterventions.pending, (state, _action) => {
        state.interventionApiStatus = APIStatus.PENDING;
      })
      .addCase(fetchAllInterventions.fulfilled, (state, action) => {
        state.interventionData = action.payload;
        state.interventionApiStatus = APIStatus.FULFILLED;
      })
      .addCase(fetchAllInterventions.rejected, (state, _action) => {
        state.interventionApiStatus = APIStatus.ERROR;
      });
  },
});

export const { handleInterventionChange, clearFilter } = interventionSlice.actions;
