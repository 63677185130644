import type { AxiosError } from 'axios';
import axios from 'axios';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { APIStatus } from '../../../enum';
import type { ErrorMessage } from '../../../models/error';
import type { AsyncThunkConfig } from '../../../models/slice';
import type { FilteredInfo, RequestParams, TableChange } from '../../../models/table';
import { SortedInfo } from '../../../models/table';
import { RaygunErrorHandlerService } from '../../../service/raygun.service';
import { removeEmpty } from '../../../service/table.service';

const { logError } = RaygunErrorHandlerService();

export type Validation = {
  created_clinician_name: string;
  details: string;
  organization: string;
  organization_id: string;
  risk_status: string;
  risk_status_feedback: string;
  sentiment: string;
  sentiment_feedback: string;
  total_count: number;
  updated_timestamp: string;
};

type ValidationSliceType = {
  validationData: Validation[];
  validationApiStatus: APIStatus;
  requestParams: RequestParams<Validation>;
  validationApiErrorText: string;
};

const initialState: ValidationSliceType = {
  validationData: [],
  validationApiErrorText: '',
  validationApiStatus: APIStatus.IDLE,
  requestParams: {
    pagination: {
      current: 1,
      pageSize: 50,
      defaultPageSize: 50,
    },
    sortedInfo: new SortedInfo<Validation>('updated_timestamp', 'descend'),
    filteredInfo: {},
  },
};

export const fetchAllValidation = createAsyncThunk<Validation[], undefined, AsyncThunkConfig>(
  'valdidation/fetchAllValidation',
  async (_, thunkAPI) => {
    try {
      const { validationSlice } = thunkAPI.getState();
      const response = (await axios.post('v2_validation', validationSlice.requestParams)) as Validation[];
      return response ?? [];
    } catch (e) {
      logError(e, ['validationSlice', 'fetchAllValidation']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const validationSlice = createSlice({
  name: 'validation',
  initialState,
  reducers: {
    handleTableChanges: (state, action: PayloadAction<TableChange<Validation>>) => {
      const filters = action.payload.filters as FilteredInfo;
      const sorter = action.payload.sorter as SortedInfo<Validation>;
      if (!sorter.order) {
        sorter.order = null;
      }
      state.requestParams.pagination = action.payload.pagination;
      state.requestParams.sortedInfo = sorter;
      state.requestParams.filteredInfo = removeEmpty(filters);
    },
    clearFilter: (state) => {
      state.requestParams.pagination = initialState.requestParams.pagination;
      state.requestParams.sortedInfo = initialState.requestParams.sortedInfo;
      state.requestParams.filteredInfo = initialState.requestParams.filteredInfo;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllValidation.pending, (state, _action) => {
        state.validationApiStatus = APIStatus.PENDING;
      })
      .addCase(fetchAllValidation.fulfilled, (state, action) => {
        state.validationData = action.payload;
        state.validationApiStatus = APIStatus.FULFILLED;
      })
      .addCase(fetchAllValidation.rejected, (state, _action) => {
        state.validationApiStatus = APIStatus.ERROR;
      });
  },
});

export const { handleTableChanges, clearFilter } = validationSlice.actions;
