import { notification } from 'antd';
import type { AxiosError } from 'axios';
import axios from 'axios';
import type { Dayjs } from 'dayjs';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { APIStatus } from '../../../../../../enum';
import type { ErrorMessage } from '../../../../../../models/error';
import type { AsyncThunkConfig } from '../../../../../../models/slice';
import { RaygunErrorHandlerService } from '../../../../../../service/raygun.service';
import type { ClientEntry } from '../entriesSlice';
import { fetchClientEntries } from '../entriesSlice';

const { logError } = RaygunErrorHandlerService();

type Emoji = {
  cldr_short_name: string;
  hex_code: string;
  id: string;
  name: number;
  unicode: string;
};

type ClientClinician = {
  clinician_id: string;
  clinician_first_name: string;
  clinician_last_name: string;
  clinician_last_first_name: string;
  assigned: boolean;
};

export type EntriesTabKey = 'feedback' | 'intervention' | 'accurate';

type ClientEntriesSliceType = {
  emojis: Emoji[];
  emojiApiStatus: APIStatus;
  sendFeedbackApiStatus: APIStatus;
  sendFeedbackParam: Feedback;
  sendInterventionApiStatus: APIStatus;
  intervention: Intervention;
  clientCliniciansApiStatus: APIStatus;
  clientClinicians: ClientClinician[];
  sendValidationApiStatus: APIStatus;
  activeTab: EntriesTabKey;
  record: ClientEntry | null;
  entriesModal: ENTRIES_MODAL;
  isFormDirty: boolean;
};

export class Feedback {
  custom_message: string | null = null;
  emoji_id: string | null = null;
  client_id: string | null = null;
}

export class Intervention {
  client_id: string | null = null;
  clinician_id: string | null = null;
  details: string | null = null;
  severity: string | null = null;
  timestamp: Dayjs | null = null;
}

export class Validation {
  client_id: string | null = null;
  client_journal_id: string | null = null;
  details: string | null = null;
  risk_status: string | null = null;
  risk_status_feedback: string | null = null;
  sentiment: string | null = null;
  sentiment_feedback: string | null = null;
}

export enum ENTRIES_MODAL {
  ENTRIES_ACTION_MODALS = 'entries_actions',
  IDLE = 'idle',
  COMMON_ACTION_MODALS = 'common_actions',
}

const initialState: ClientEntriesSliceType = {
  emojis: [],
  emojiApiStatus: APIStatus.IDLE,
  sendFeedbackApiStatus: APIStatus.IDLE,
  sendFeedbackParam: new Feedback(),
  clientClinicians: [],
  intervention: new Intervention(),
  clientCliniciansApiStatus: APIStatus.IDLE,
  sendInterventionApiStatus: APIStatus.IDLE,
  sendValidationApiStatus: APIStatus.IDLE,
  entriesModal: ENTRIES_MODAL.IDLE,
  activeTab: 'feedback',
  record: null,
  isFormDirty: false,
};

export const fetchEmojis = createAsyncThunk<Emoji[], undefined, AsyncThunkConfig>(
  'clientEntriesModal/fetchEmojis',
  async (_, thunkAPI) => {
    try {
      const response = (await axios.get('v3_get_feedback_emoji')) as Emoji[];
      return response ?? [];
    } catch (e) {
      logError(e, ['clientEntriesModalSlice', 'fetchEmojis']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const sendFeedback = createAsyncThunk<number, Feedback, AsyncThunkConfig>(
  'clientEntriesModal/sendFeedback',
  async (sendFeedbackParam, thunkAPI) => {
    try {
      const response = (await axios.post('v3_send_feedback', sendFeedbackParam)) as number;
      if (sendFeedbackParam.client_id && response != null) {
        thunkAPI.dispatch(fetchClientEntries(sendFeedbackParam.client_id));
        thunkAPI.dispatch(handleCancel());
      }
      return response; // sent_feedback number
    } catch (e) {
      thunkAPI.dispatch(handleCancel());
      logError(e, ['clientEntriesModalSlice', 'sendFeedback']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const sendIntervention = createAsyncThunk<string, Intervention, AsyncThunkConfig>(
  'clientEntriesModal/sendIntervention',
  async (intervention, thunkAPI) => {
    try {
      const response = (await axios.post('v2_intervention_upsert', intervention)) as string;
      if (intervention.client_id && response != null) {
        thunkAPI.dispatch(fetchClientEntries(intervention.client_id));
        thunkAPI.dispatch(handleCancel());
      }
      return response; // intervention_id
    } catch (e) {
      thunkAPI.dispatch(handleCancel());
      logError(e, ['clientEntriesModalSlice', 'sendIntervention']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const fetchClientClinicians = createAsyncThunk<ClientClinician[], string, AsyncThunkConfig>(
  'clientEntriesModal/fetchClientClinicians',
  async (client_id, thunkAPI) => {
    try {
      const response = (await axios.post('v2_client_clinician', { client_id: client_id })) as ClientClinician[];
      return response ?? [];
    } catch (e) {
      logError(e, ['clientEntriesModalSlice', 'fetchClientClinicians']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const sendValidation = createAsyncThunk<string, Validation, AsyncThunkConfig>(
  'clientEntriesModal/sendValidation',
  async (validation, thunkAPI) => {
    try {
      const response = (await axios.post('v2_validation_upsert', validation)) as string;
      if (response != null) {
        thunkAPI.dispatch(handleCancel());
      }
      return response; // validation_id
    } catch (e) {
      thunkAPI.dispatch(handleCancel());
      logError(e, ['clientEntriesModalSlice', 'sendValidation']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const clientEntriesModalSlice = createSlice({
  name: 'clientEntriesModal',
  initialState,
  reducers: {
    setEntriesModal: (state, action: PayloadAction<ENTRIES_MODAL>) => {
      state.entriesModal = action.payload;
    },
    changeActiveTab: (state, action: PayloadAction<EntriesTabKey>) => {
      state.activeTab = action.payload;
    },
    setCurrentEntry: (state, action: PayloadAction<ClientEntry>) => {
      state.record = action.payload;
    },
    setIsFormDirty: (state, action: PayloadAction<boolean>) => {
      state.isFormDirty = action.payload;
    },
    handleCancel: (state) => {
      state.entriesModal = ENTRIES_MODAL.IDLE;
      state.activeTab = 'feedback';
      state.record = null;
      state.isFormDirty = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchEmojis.pending, (state, _action) => {
      state.emojiApiStatus = APIStatus.PENDING;
    });
    builder.addCase(fetchEmojis.fulfilled, (state, action) => {
      state.emojis = action.payload;
      state.emojiApiStatus = APIStatus.FULFILLED;
    });
    builder.addCase(sendFeedback.pending, (state, _action) => {
      state.sendFeedbackApiStatus = APIStatus.PENDING;
    });
    builder.addCase(sendFeedback.fulfilled, (state, _action) => {
      state.sendFeedbackApiStatus = APIStatus.FULFILLED;
      notification.destroy();
      notification.success({
        message: 'Success',
        description: 'Feedback sent successfully.',
      });
    });
    builder.addCase(sendFeedback.rejected, (state) => {
      state.sendFeedbackApiStatus = APIStatus.ERROR;
    });

    builder.addCase(fetchClientClinicians.pending, (state, _action) => {
      state.clientCliniciansApiStatus = APIStatus.PENDING;
    });
    builder.addCase(fetchClientClinicians.fulfilled, (state, action) => {
      state.clientClinicians = action.payload;
      state.clientCliniciansApiStatus = APIStatus.FULFILLED;
    });

    builder.addCase(sendIntervention.pending, (state, _action) => {
      state.sendInterventionApiStatus = APIStatus.PENDING;
    });
    builder.addCase(sendIntervention.fulfilled, (state, _action) => {
      state.sendInterventionApiStatus = APIStatus.FULFILLED;
      notification.destroy();
      notification.success({
        message: 'Success',
        description: 'Intervention saved successfully',
      });
    });
    builder.addCase(sendIntervention.rejected, (state) => {
      state.sendInterventionApiStatus = APIStatus.ERROR;
    });

    builder.addCase(sendValidation.pending, (state, _action) => {
      state.sendValidationApiStatus = APIStatus.PENDING;
    });
    builder.addCase(sendValidation.fulfilled, (state, _action) => {
      state.sendValidationApiStatus = APIStatus.FULFILLED;
      notification.destroy();
      notification.success({
        message: 'Success',
        description: 'Thank you for your feedback.',
      });
    });
    builder.addCase(sendValidation.rejected, (state) => {
      state.sendValidationApiStatus = APIStatus.ERROR;
    });
  },
});

export const { setEntriesModal, handleCancel, changeActiveTab, setCurrentEntry, setIsFormDirty } =
  clientEntriesModalSlice.actions;
