import { notification } from 'antd';
import type { AxiosError } from 'axios';
import axios from 'axios';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import type { ErrorMessage } from '../../../models/error';
import type { AsyncThunkConfig } from '../../../models/slice';
import { RaygunErrorHandlerService } from '../../../service/raygun.service';
import type { AdminClinician } from '../admin-clinicians/adminClinicianSlice';

const { logError } = RaygunErrorHandlerService();

export enum MODAL {
  IDLE = 'idle',
  EDIT_SHIFT = 'edit_shift',
  EDIT_PHONE = 'edit_phone',
  EDIT_RESOURCE = 'edit_resource',
}

class UpdateAdminOnCallWeekend {
  schedule_role: string | null = null;
  clinician_id: string | null = null;
}

type UpdateClinicianPhone = {
  mobile_phone: string;
  clinician_id: string;
};

export class OnCallShift extends UpdateAdminOnCallWeekend {
  dates_string: string | null = null;
  short_dates_string: string | null = null;
  month: string | null = null;
  clinician_name: string | null = null;
  mobile_phone: string | null = null;
  saturday_date: string | null = null;
}

type HHCWeekendSliceType = {
  weekendSchedule: OnCallShift[];
  hhcClinicians: AdminClinician[];
  resource: string;
  shift: OnCallShift | null;
  loaded: boolean;
  totalRows: number;
  showModal: MODAL;
  loading: boolean;
};

const initialState: HHCWeekendSliceType = {
  weekendSchedule: [],
  hhcClinicians: [],
  resource: '',
  shift: null,
  loaded: false,
  totalRows: 0,
  showModal: MODAL.IDLE,
  loading: false,
};

export const getWeekendSchedules = createAsyncThunk<OnCallShift[], undefined, AsyncThunkConfig>(
  'hhcWeekend/getWeekendSchedule',
  async (_, thunkAPI) => {
    try {
      const response = (await axios.post('v2_weekend_schedule_extended')) as OnCallShift[];
      return response ?? [];
    } catch (e) {
      logError(e, ['hhcWeekendSlice', 'getWeekendSchedule']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const getHHCClinicians = createAsyncThunk<AdminClinician[], undefined, AsyncThunkConfig>(
  'hhcWeekend/getHHCClinician',
  async (_, thunkAPI) => {
    try {
      const filters = {
        sortedInfo: {
          order: 'ASC',
          field: 'name',
          columnKey: 'name',
        },
        filteredInfo: {
          account_status: ['created', 'invited', 'active'],
          organization: ['Hartford Health Care'],
        },
      };
      const response = (await axios.post('v2_admin_clinician', filters)) as AdminClinician[];
      return response ?? [];
    } catch (e) {
      logError(e, ['hhcWeekendSlice', 'getHHCClinician']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

const phoneRegex = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;

export const updateClinicianPhone = createAsyncThunk<string, UpdateClinicianPhone, AsyncThunkConfig>(
  'hhcWeekend/updateClinicianPhone',
  async (updateClinicianPhone, thunkAPI) => {
    try {
      if (updateClinicianPhone.mobile_phone.match(phoneRegex)) {
        const response = (await axios.post('v3_clinician_mobile_update', updateClinicianPhone)) as string;
        if (response) {
          thunkAPI.dispatch(getWeekendSchedules());
          notification.destroy();
          notification.success({
            message: 'Phone number updated',
            description: 'Success',
          });
        }
        return response; // clinician_id
      } else {
        notification.error({
          message: 'Invalid phone number',
          description: 'Invalid',
        });
      }
      return '';
    } catch (e) {
      logError(e, ['hhcWeekendSlice', 'updateClinicianPhone']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const getResource = createAsyncThunk<string, undefined, AsyncThunkConfig>(
  'hhcWeekend/getResource',
  async (_, thunkAPI) => {
    try {
      const response = (await axios.get('v2_weekend_schedule_resource')) as string;
      return response;
    } catch (e) {
      logError(e, ['hhcWeekendSlice', 'getResource']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const updateResource = createAsyncThunk<string, string, AsyncThunkConfig>(
  'hhcWeekend/updateResource',
  async (markup, thunkAPI) => {
    try {
      const response = (await axios.post('v2_weekend_schedule_resource_update', { markup })) as string;
      if (response) {
        thunkAPI.dispatch(getResource());
        notification.destroy();
        notification.success({
          message: 'Resource updated',
          description: 'Success',
        });
        return response;
      } else {
        notification.error({
          message: 'Resource updated',
          description: 'Failure',
        });
      }
      return '';
    } catch (e) {
      logError(e, ['hhcWeekendSlice', 'updateResource']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const updateOnCallWeekend = createAsyncThunk<OnCallShift[], UpdateAdminOnCallWeekend, AsyncThunkConfig>(
  'hhcWeekend/updateOnCallWeekend',
  async (upsertSchedule, thunkAPI) => {
    try {
      const response = (await axios.post('v2_weekend_schedule_role_update', upsertSchedule)) as OnCallShift[];
      if (response) {
        notification.destroy();
        notification.success({
          message: 'Shift assigned successfully',
          description: 'Success',
        });
      }
      return response;
    } catch (e) {
      logError(e, ['hhcWeekendSlice', 'updateOnCallWeekend']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const hhcWeekendSlice = createSlice({
  name: 'hhcWeekend',
  initialState,
  reducers: {
    showEditModal: (state, action: PayloadAction<MODAL>) => {
      state.showModal = action.payload;
    },
    setShift: (state, action: PayloadAction<OnCallShift>) => {
      state.shift = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWeekendSchedules.fulfilled, (state, action) => {
        state.weekendSchedule = action.payload;
        state.showModal = MODAL.IDLE;
      })
      .addCase(getHHCClinicians.fulfilled, (state, action) => {
        state.hhcClinicians = action.payload;
      })
      .addCase(updateOnCallWeekend.fulfilled, (state, action) => {
        state.weekendSchedule = action.payload;
        state.showModal = MODAL.IDLE;
      })

      .addCase(getResource.fulfilled, (state, action) => {
        state.resource = action.payload;
        state.showModal = MODAL.IDLE;
      })
      .addCase(updateResource.fulfilled, (state, action) => {
        state.resource = action.payload;
        state.showModal = MODAL.IDLE;
      });
  },
});

export const { showEditModal, setShift, setLoading } = hhcWeekendSlice.actions;
