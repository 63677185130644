import type { AxiosError } from 'axios';
import axios from 'axios';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { APIStatus, UserRole } from '../../../enum';
import { environment } from '../../../environment/environment';
import type { ErrorMessage } from '../../../models/error';
import type { AsyncThunkConfig } from '../../../models/slice';
import { RaygunErrorHandlerService } from '../../../service/raygun.service';
import { combineResponseAsCSV, combineResponses } from './report.helper';

const { logError } = RaygunErrorHandlerService();

export type CheckinCount = {
  organization_name: string;
  products: Record<string, number>;
  org_total_checkins: number;
  org_total_engaged_clients: number;
  org_total_clients: number;
  engaged_rate: number;
};

class ReportRequestBody {
  organization_id: string = '';
  site_id: string = '';
  diagnosis_id: string = '';
  end_date: Dayjs = dayjs();
  start_date: Dayjs = dayjs().subtract(2, 'weeks');
}

export type ReportData = {
  data: number[] | number;
  type: string;
  name: string;
};

class Report {
  description: string = '';
  endpoint: string = '';
  format: string = '';
  id: string = '';
  name: string = '';
  show_age_filter: boolean = true;
  show_date_range_selecter: boolean = true;
  show_diagnoses_filter: boolean = true;
  show_gender_filter: boolean = true;
  show_site_filter: boolean = true;
  show_start_end_date_filter: boolean = true;
}

export enum GraphType {
  BarLine = 'bar-line',
  Line = 'line',
  Table = 'table',
}

class SelectedReport {
  id: string = '';
  title: string = '';
  description: string = '';
  graphType: GraphType | undefined = undefined;
  siteName: string = '';
  diagnosisName: string = '';
  organizationName: string = '';
  dateRange: string = '';
}

type AdminReportsSliceType = {
  adminReportsData: Report[];
  adminSelectedReportData: ReportData[] | CheckinCount[];
  adminSelectedReportAsCSV: string;
  adminApiStatus: APIStatus;
  adminApiCSVStatus: APIStatus;
  adminAPIErrorText: string;
  selectedReport: SelectedReport;
  reportRequestBody: ReportRequestBody;
  tempSelectedReport: Report;
};

const initialState: AdminReportsSliceType = {
  adminReportsData: [],
  adminSelectedReportData: [],
  adminSelectedReportAsCSV: '',
  adminApiStatus: APIStatus.IDLE,
  adminApiCSVStatus: APIStatus.IDLE,
  adminAPIErrorText: '',
  selectedReport: new SelectedReport(),
  reportRequestBody: new ReportRequestBody(),
  tempSelectedReport: new Report(),
};

export const fetchAllReports = createAsyncThunk<Report[], undefined, AsyncThunkConfig>(
  'adminReport/fetchAllReports',
  async (_, thunkAPI) => {
    try {
      const response = await axios.get('v3_report');
      return (response as unknown as Report[]) ?? [];
    } catch (e) {
      logError(e, ['adminReportSlice', 'fetchAllReports']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const fetchSelectedReport = createAsyncThunk<ReportData[], undefined, AsyncThunkConfig>(
  'adminReport/fetchSelectedReport',
  async (_, thunkAPI) => {
    try {
      const { authSlice, adminReportSlice, adminOrganizationSlice, adminSitesSlice, clientUpsertSlice } =
        thunkAPI.getState();
      thunkAPI.dispatch(
        handleSelectedReportChange({
          ...adminReportSlice.selectedReport,
          organizationName:
            (authSlice.currentUser.role === UserRole.ADMIN &&
              adminOrganizationSlice.adminOrganizationsData.find(
                (org) => org.id === adminReportSlice.reportRequestBody.organization_id,
              )?.name) ||
            '',
          siteName:
            adminSitesSlice.adminSites.find((site) => site.id === adminReportSlice.reportRequestBody.site_id)?.name ||
            '',
          diagnosisName:
            clientUpsertSlice.diagnoses.find((d) => d.diagnosis_id === adminReportSlice.reportRequestBody.diagnosis_id)
              ?.diagnosis_name || '',
        }),
      );

      const data = {
        ...adminReportSlice.reportRequestBody,
        organization_id: adminReportSlice.reportRequestBody.organization_id || null,
        site_id: adminReportSlice.reportRequestBody.site_id || null,
        diagnosis_id: adminReportSlice.reportRequestBody.diagnosis_id || null,
      };

      const requests = [axios.post(`${environment.baseEndpoint}${adminReportSlice.tempSelectedReport.endpoint}`, data)];

      if (authSlice.authResult2.token) {
        requests.push(
          axios.post(`${environment.secondEndpoint}${adminReportSlice.tempSelectedReport.endpoint}`, data, {
            baseURL: environment.secondEndpoint,
          }),
        );
      }

      const [response1, response2] = await axios.all(requests);
      return combineResponses(response1 as unknown as ReportData[], response2 as unknown as ReportData[]);
    } catch (e) {
      logError(e, ['adminReportSlice', 'fetchSelectedReport']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const fetchSelectedReportAsCSV = createAsyncThunk<string, undefined, AsyncThunkConfig>(
  'adminReport/fetchSelectedReportAsCSV',
  async (_, thunkAPI) => {
    try {
      const { authSlice, adminReportSlice } = thunkAPI.getState();
      const body = {
        ...adminReportSlice.reportRequestBody,
        csv: true,
        organization_id: adminReportSlice.reportRequestBody.organization_id || null,
        site_id: adminReportSlice.reportRequestBody.site_id || null,
        diagnosis_id: adminReportSlice.reportRequestBody.diagnosis_id || null,
      };
      const requests: Promise<string>[] = [
        axios.post(`${environment.baseEndpoint}${adminReportSlice.tempSelectedReport.endpoint}`, body),
      ];

      if (authSlice.authResult2.token) {
        requests.push(
          axios.post(`${environment.secondEndpoint}${adminReportSlice.tempSelectedReport.endpoint}`, body, {
            baseURL: environment.secondEndpoint,
          }),
        );
      }

      const [response1, response2] = await axios.all(requests);
      const result = combineResponseAsCSV(response1, response2);
      return result;
    } catch (e) {
      logError(e, ['adminReportSlice', 'fetchSelectedReportAsCSV']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const adminReportSlice = createSlice({
  name: 'adminReport',
  initialState,
  reducers: {
    handleSelectedReportChange: (state, action: PayloadAction<SelectedReport>) => {
      state.selectedReport = action.payload;
    },
    handleReportRequestBodyChange: (state, action: PayloadAction<Partial<ReportRequestBody>>) => {
      state.reportRequestBody = { ...state.reportRequestBody, ...action.payload };
    },
    handleTempSelectedReportChange: (state, action: PayloadAction<Report>) => {
      state.tempSelectedReport = action.payload;
    },
    setReportAsCSV: (state, action: PayloadAction<string>) => {
      state.adminSelectedReportAsCSV = action.payload;
    },
    clearFilters: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllReports.pending, (state, _action) => {
        state.adminApiStatus = APIStatus.PENDING;
      })
      .addCase(fetchAllReports.fulfilled, (state, action) => {
        state.adminReportsData = action.payload;
        state.adminApiStatus = APIStatus.FULFILLED;
      })
      .addCase(fetchAllReports.rejected, (state, _action) => {
        state.adminApiStatus = APIStatus.ERROR;
      })
      .addCase(fetchSelectedReport.pending, (state, _action) => {
        state.adminApiStatus = APIStatus.PENDING;
      })
      .addCase(fetchSelectedReport.fulfilled, (state, action) => {
        state.adminSelectedReportData = action.payload;
        state.selectedReport = {
          ...state.selectedReport,
          id: state.tempSelectedReport.id,
          title: state.tempSelectedReport.name,
          description: state.tempSelectedReport.description,
          graphType: state.tempSelectedReport.format as GraphType,
          dateRange: `${state.reportRequestBody.start_date.format(
            'YYYY-MM-DD',
          )} to ${state.reportRequestBody.end_date.format('YYYY-MM-DD')}`,
        };
        state.adminApiStatus = APIStatus.FULFILLED;
      })
      .addCase(fetchSelectedReport.rejected, (state, _action) => {
        state.adminApiStatus = APIStatus.ERROR;
      })
      .addCase(fetchSelectedReportAsCSV.pending, (state, _action) => {
        state.adminApiCSVStatus = APIStatus.PENDING;
      })
      .addCase(fetchSelectedReportAsCSV.fulfilled, (state, action) => {
        state.adminSelectedReportAsCSV = action.payload;
        state.adminApiCSVStatus = APIStatus.FULFILLED;
      })
      .addCase(fetchSelectedReportAsCSV.rejected, (state, _action) => {
        state.adminApiCSVStatus = APIStatus.ERROR;
      });
  },
});

export const {
  handleSelectedReportChange,
  handleReportRequestBodyChange,
  handleTempSelectedReportChange,
  setReportAsCSV,
  clearFilters,
} = adminReportSlice.actions;
