import type { AxiosError } from 'axios';
import axios from 'axios';
import type { Key } from 'react';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import type { ErrorMessage } from '../../../../models/error';
import type { AsyncThunkConfig } from '../../../../models/slice';
import { RaygunErrorHandlerService } from '../../../../service/raygun.service';
import type { AdminClinician } from '../adminClinicianSlice';

const { logError } = RaygunErrorHandlerService();

type ClinicianBulkAssignSliceType = {
  clinicianBulkSiteAssign: ClinicianBulkSiteAssign;
  showBulkAssignmentModal: boolean;
  selectedClinicians: AdminClinician[];
  notAssignedClinicians: AdminClinician[];
  singleAssignmentClinicians: AdminClinician[];
  alreadyAssignedClinicians: AdminClinician[];
  loading: boolean;
  selectedRowKeys: Key[];
  bulkSiteAssignResponse: BulkSiteAssignResponse;
  nextButtonDisabled: boolean;
};

class ClinicianBulkSiteAssign {
  clinician_ids: string[] = [];
  site_id: string = '';
  assign: boolean = false;
}

class BulkSiteAssignResponse {
  num_clinicians: number = 0;
  site_name: string = '';
}

const initialState: ClinicianBulkAssignSliceType = {
  clinicianBulkSiteAssign: new ClinicianBulkSiteAssign(),
  showBulkAssignmentModal: false,
  selectedClinicians: [],
  notAssignedClinicians: [],
  singleAssignmentClinicians: [],
  alreadyAssignedClinicians: [],
  loading: false,
  selectedRowKeys: [],
  bulkSiteAssignResponse: new BulkSiteAssignResponse(),
  nextButtonDisabled: true,
};

export const clinicianBulkAssign = createAsyncThunk<BulkSiteAssignResponse, undefined, AsyncThunkConfig>(
  'clinicianBulkAssignment/clinicianBulkAssign',
  async (_, thunkAPI) => {
    try {
      const { clinicianBulkAssignmentSlice } = thunkAPI.getState();
      const response = await axios.post(
        'v3_clinician_bulk_site_assignment',
        clinicianBulkAssignmentSlice.clinicianBulkSiteAssign,
      );
      return (response as unknown as BulkSiteAssignResponse) ?? initialState.bulkSiteAssignResponse;
    } catch (e) {
      logError(e, ['clinicianBulkAssignmentSlice', 'clinicianBulkAssign']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const clinicianBulkAssignmentSlice = createSlice({
  name: 'clinicianBulkAssignment',
  initialState,
  reducers: {
    setClinicianIds: (state, action: PayloadAction<Key[]>) => {
      state.clinicianBulkSiteAssign = { ...state.clinicianBulkSiteAssign, clinician_ids: action.payload as string[] };
      state.selectedRowKeys = action.payload;
    },
    setAssign: (state, action: PayloadAction<boolean>) => {
      state.clinicianBulkSiteAssign.assign = action.payload;
      state.showBulkAssignmentModal = true;
    },
    setSiteId: (state, action: PayloadAction<string>) => {
      state.clinicianBulkSiteAssign.site_id = action.payload;
      state.nextButtonDisabled = false;
    },
    setSelectedClinicians: (state, action: PayloadAction<AdminClinician[]>) => {
      state.selectedClinicians = action.payload;
    },
    setCancel: (state) => {
      state.showBulkAssignmentModal = false;
      state.clinicianBulkSiteAssign.assign = false;
      state.clinicianBulkSiteAssign.site_id = '';
      state.nextButtonDisabled = true;
    },
    clearRowSelection: (state) => {
      state.showBulkAssignmentModal = false;
      state.clinicianBulkSiteAssign = new ClinicianBulkSiteAssign();
      state.selectedRowKeys = [];
    },
    getStep2Data: (state) => {
      if (state.clinicianBulkSiteAssign.site_id) {
        state.notAssignedClinicians = state.selectedClinicians.filter((clinician) => {
          if (!clinician) return false;
          if (clinician.site_ids && clinician.site_ids.length > 0) {
            return clinician.site_ids.every((item) => item !== state.clinicianBulkSiteAssign.site_id);
          }
          return true;
        });

        state.singleAssignmentClinicians = state.selectedClinicians.filter((clinician) => {
          if (clinician && clinician.site_ids && clinician.site_ids.length === 1) {
            return clinician.site_ids[0] === state.clinicianBulkSiteAssign.site_id;
          }
          return false;
        });

        state.alreadyAssignedClinicians = state.selectedClinicians.filter((clinician) => {
          if (clinician && clinician.site_ids && clinician.site_ids.length > 0) {
            return clinician.site_ids?.includes(state.clinicianBulkSiteAssign.site_id);
          }
          return false;
        });
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(clinicianBulkAssign.pending, (state, _action) => {
        state.loading = true;
      })
      .addCase(clinicianBulkAssign.fulfilled, (state, action) => {
        state.loading = false;
        state.bulkSiteAssignResponse = action.payload;
      })
      .addCase(clinicianBulkAssign.rejected, (state, _action) => {
        state.loading = false;
      });
  },
});

export const {
  setClinicianIds,
  setAssign,
  setSiteId,
  setSelectedClinicians,
  getStep2Data,
  setCancel,
  clearRowSelection,
} = clinicianBulkAssignmentSlice.actions;
