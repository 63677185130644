import { useEffect } from 'react';
import { Modal } from 'antd';

import { useCapturePostHog } from './useCapturePostHog';

const { confirm } = Modal;

export const useCheckOrientation = () => {
  const { capturePostHogCustomEvent } = useCapturePostHog();

  useEffect(() => {
    const checkOrientation = () => {
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      if (window.innerHeight > window.innerWidth && isMobile) {
        confirm({
          title: '',
          icon: '',
          centered: true,
          cancelButtonProps: { style: { display: 'none' } },
          content: `For the best experience,
        please rotate your phone sideways to Landscape mode`,
          onOk() {
            capturePostHogCustomEvent('CheckOrientation rotate phone');
          },
          onCancel() {
            capturePostHogCustomEvent('CheckOrientation close modal');
          },
        });
      }
    };
    checkOrientation();
  }, [capturePostHogCustomEvent]);
};
