import { notification } from 'antd';
import type { AxiosError } from 'axios';
import axios from 'axios';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { APIStatus } from '../../../enum';
import type { ErrorMessage } from '../../../models/error';
import type { AsyncThunkConfig } from '../../../models/slice';
import type { FilteredInfo, RequestParams, TableChange } from '../../../models/table';
import { SortedInfo } from '../../../models/table';
import { RaygunErrorHandlerService } from '../../../service/raygun.service';
import { removeEmpty } from '../../../service/table.service';
import { validateAdminSite } from './admin-sites.helper';

const { logError } = RaygunErrorHandlerService();

export class AdminSite {
  id: string | null = null;
  name: string | null = null;
  city: string | null = null;
  address: string | null = null;
  country: string | null = null;
  phone: string | null = null;
  postal_code: string | null = null;
  state: string | null = null;
  email: string | null = null;
  organization: string | null = null;
  organization_id: string | null = null;
  total_count: number = 0;
  clinicians: string[] = [];
  client_assignments: number = 0;
  clinician_assignments: number = 0;
  archived: boolean = false;
}

export enum ADMIN_SITES_MODAL {
  PASSWORD_RESET = 'password_reset',
  IDLE = 'idle',
  EDIT_SITE = 'edit_site',
  DELETE_SITE = 'delete_site',
  ARCHIVE_SITE = 'archive_site',
}

type AdminSitesSliceType = {
  adminSites: AdminSite[];
  requestAdminSitesParam: RequestParams<AdminSite>;
  adminApiStatus: APIStatus;
  adminAPIErrorText: string;
  modal: ADMIN_SITES_MODAL;
  adminSite: AdminSite;
};

const initialState: AdminSitesSliceType = {
  adminSites: [],
  requestAdminSitesParam: {
    pagination: {
      current: 1,
      pageSize: 50,
      defaultPageSize: 50,
    },
    sortedInfo: new SortedInfo<AdminSite>('name', 'ascend'),
    filteredInfo: {},
  },
  adminApiStatus: APIStatus.IDLE,
  adminAPIErrorText: '',
  modal: ADMIN_SITES_MODAL.IDLE,
  adminSite: new AdminSite(),
};

export const fetchAdminSites = createAsyncThunk<AdminSite[], undefined, AsyncThunkConfig>(
  'adminSites/fetchAdminSites',
  async (_, thunkAPI) => {
    try {
      const { adminSitesSlice } = thunkAPI.getState();
      const response = (await axios.post('v2_admin_site', adminSitesSlice.requestAdminSitesParam)) as AdminSite[];
      return response ?? [];
    } catch (e) {
      logError(e, ['adminSitesSlice', 'fetchAdminSites']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const fetchAllSites = createAsyncThunk<AdminSite[], undefined, AsyncThunkConfig>(
  'adminSites/fetchAllSites',
  async (_, thunkAPI) => {
    try {
      const response = (await axios.post('v2_admin_site')) as AdminSite[];
      return response ?? [];
    } catch (e) {
      logError(e, ['adminSitesSlice', 'fetchAllSites']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export type DeleteAdminSiteParam = { id: string; organization_id: string };

export const deleteAdminSite = createAsyncThunk<string | null, DeleteAdminSiteParam, AsyncThunkConfig>(
  'adminSites/deleteSite',
  async ({ id, organization_id }, thunkAPI) => {
    try {
      const response = (await axios.post('v2_admin_site_delete', { id, organization_id })) as string; //deleted_site_id;
      if (response) {
        notification.success({
          message: 'Site deleted successfully',
          description: 'Success',
        });
        await thunkAPI.dispatch(fetchAdminSites());
        thunkAPI.dispatch(closeAdminSiteModal());
      }
      return response ?? '';
    } catch (e) {
      logError(e, ['adminSitesSlice', 'deleteAdminSite']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const upsertAdminSite = createAsyncThunk<string | null, AdminSite, AsyncThunkConfig>(
  'adminSites/upsertAdminSite',
  async (adminSiteToUpsert, thunkAPI) => {
    try {
      if (validateAdminSite(adminSiteToUpsert)) {
        const response = (await axios.post('v2_admin_site_upsert', adminSiteToUpsert)) as string; // id;
        if (response) {
          notification.success({
            message: 'Site updated successfully',
            description: 'Success',
          });
          await thunkAPI.dispatch(fetchAdminSites());
          thunkAPI.dispatch(closeAdminSiteModal());
        }
        return response ?? '';
      }
      return null;
    } catch (e) {
      logError(e, ['adminSitesSlice', 'upsertAdminSite']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export type ArchiveAdminSiteParam = DeleteAdminSiteParam & { archive: boolean };

export const archiveAdminSite = createAsyncThunk<string | null, ArchiveAdminSiteParam, AsyncThunkConfig>(
  'adminSites/deleteSite',
  async (archiveAdminSiteParam, thunkAPI) => {
    try {
      const response = (await axios.post('v2_admin_site_archive', archiveAdminSiteParam)) as string; // id;
      if (response) {
        notification.success({
          message: `Site ${archiveAdminSiteParam.archive ? 'archived' : 'unarchived'} successfully`,
          description: 'Success',
        });
        await thunkAPI.dispatch(fetchAdminSites());
        thunkAPI.dispatch(closeAdminSiteModal());
      }
      return response ?? '';
    } catch (e) {
      logError(e, ['adminSitesSlice', 'deleteAdminSite']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const adminSitesSlice = createSlice({
  name: 'adminSites',
  initialState,
  reducers: {
    handleSitesTableChange: (state, action: PayloadAction<TableChange<AdminSite>>) => {
      const filters = action.payload.filters as FilteredInfo;
      const sorter = action.payload.sorter as SortedInfo<AdminSite>;
      if (!sorter.order) {
        sorter.order = null;
      }
      state.requestAdminSitesParam.pagination = action.payload.pagination;
      state.requestAdminSitesParam.sortedInfo = sorter;
      state.requestAdminSitesParam.filteredInfo = removeEmpty(filters);
    },
    clearFilter: (state) => {
      state.requestAdminSitesParam.pagination = initialState.requestAdminSitesParam.pagination;
      state.requestAdminSitesParam.sortedInfo = initialState.requestAdminSitesParam.sortedInfo;
      state.requestAdminSitesParam.filteredInfo = initialState.requestAdminSitesParam.filteredInfo;
    },
    setAdminSite: (state, action) => {
      state.adminSite = action.payload;
    },
    setAdminSiteModal: (state, action: PayloadAction<ADMIN_SITES_MODAL>) => {
      state.modal = action.payload;
    },
    closeAdminSiteModal: (state) => {
      state.modal = ADMIN_SITES_MODAL.IDLE;
      state.adminSite = new AdminSite();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdminSites.pending, (state, _action) => {
        state.adminApiStatus = APIStatus.PENDING;
      })
      .addCase(fetchAdminSites.fulfilled, (state, action) => {
        state.adminSites = action.payload;
        state.adminApiStatus = APIStatus.FULFILLED;
      })
      .addCase(fetchAdminSites.rejected, (state, action) => {
        state.adminApiStatus = APIStatus.ERROR;
        state.adminAPIErrorText = (action.payload as ErrorMessage)?.displayText;
      })
      .addCase(fetchAllSites.pending, (state, _action) => {
        state.adminApiStatus = APIStatus.PENDING;
      })
      .addCase(fetchAllSites.fulfilled, (state, action) => {
        state.adminSites = action.payload;
        state.adminApiStatus = APIStatus.FULFILLED;
      })
      .addCase(fetchAllSites.rejected, (state, _action) => {
        state.adminApiStatus = APIStatus.ERROR;
      });
  },
});

export const { handleSitesTableChange, clearFilter, setAdminSite, setAdminSiteModal, closeAdminSiteModal } =
  adminSitesSlice.actions;
