export enum APIStatus {
  IDLE = 'idle',
  PENDING = 'pending',
  FULFILLED = 'fulfilled',
  FAILED = 'failed',
  ERROR = 'error',
}

export enum sortOrder {
  ascend = 'ASC',
  descend = 'DESC',
}

export enum RepeatPeriod {
  NoReminder = 'never',
  Daily = '1 day',
  Weekly = '1 week',
  Monthly = '1 month',
}

export enum ScheduleRepeatPeriod {
  Day = '1 day',
  Week = '7 days',
  Month = '1 mon',
}

export enum JournallingPeriod {
  OneDay = '1 day',
  ThreeDays = '3 days',
  OneWeek = '1 week',
}

export enum OnTrakProduct {
  ReQoL10a = '25edc875-a993-40f7-857c-5b7bd9548f48',
  ReQoL10b = '22b42ed4-7157-4322-9666-00f90193eac7',
  ReQoL20 = 'd0f0e864-5772-487b-abd4-0b46d6869743',
}

export enum YaleProduct {
  AMSurvey = 'a01dbf0b-6867-40ad-b48a-20bde97a95f3',
  PMSurvey = '4152f030-3784-4d62-9cd5-019b00c83372',
}

export enum AllClinicianRole {
  ORGANIZATION = 'organization',
  SITE = 'site',
  CLINICIAN = 'clinician',
  PROGRAM_ADMINISTRATION = 'program_administration',
}

export enum ExternalClinicianRole {
  ORGANIZATION = 'organization',
  SITE = 'site',
  CLINICIAN = 'clinician',
}

export enum SiteClinicianRole {
  SITE = 'site',
  CLINICIAN = 'clinician',
}

export enum UserOrganization {
  YALE = '416b1e9e-ce76-4d7a-9135-de40d0534913',
  APA = '8ceebcc8-2c82-49c7-ba46-1851079d10a0',
  MKI = 'bef03fbd-1ce5-44bf-8dac-593d6003963a',
  OSI = '2e129b2f-d501-474e-bed1-6ebce470d7f7',
  ONTRAK = 'b52211b2-1971-4adb-8dad-c4ec4d552f3a',
}

export enum UserRole {
  ADMIN = 'trycycle',
  ORGANIZATION = 'organization',
  SITE = 'site',
  CLINICIAN = 'clinician',
  PROGRAM_ADMINISTRATION = 'program_administration',
}

export enum UserDiagnosis {
  OTHER = 'c53ba470-e49c-42ac-8c32-cf13b76af662',
}

export enum Status {
  na,
  green,
  yellow,
  red,
  null,
}

export enum LikertScale {
  None,
  Slight,
  Mild,
  Moderate,
  Severe,
}

export const LikertScaleDescription: Record<LikertScale, string> = {
  [LikertScale.None]: ' None (Not at all) ',
  [LikertScale.Slight]: 'Slight (Rare, less than a day or two)',
  [LikertScale.Mild]: 'Mild (Several days)',
  [LikertScale.Moderate]: 'Moderate (More than half the days)',
  [LikertScale.Severe]: 'Severe (Nearly every day)',
} as const;

export type StatusKey = keyof typeof Status;
