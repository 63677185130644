import { useEffect, useState } from 'react';
import type { MenuProps } from 'antd';
import { Menu, Modal } from 'antd';
import { useLocation, useNavigate } from 'react-router';

import { LogoutOutlined } from '@ant-design/icons';

import { UserRole } from '../../enum';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useCapturePostHog } from '../../hooks/useCapturePostHog';
import { logout } from '../pages/Login/authSlice';
import { getAdminMenuItems, getMenuItems, getProgramAdministrationMenuItems } from './layout.helper';

export const SideNavMenu = () => {
  const { capturePostHogCustomEvent } = useCapturePostHog();
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname.split('/page')[1];
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector((state) => state.authSlice.currentUser);
  const authResult = useAppSelector((state) => state.authSlice.authResult);
  const missedScheduledProductCount = useAppSelector(
    (state) => state.scheduledProductsSlice.missedScheduledProductCount,
  );
  const [openKeys, setOpenKeys] = useState<string[]>();

  useEffect(() => {
    if (currentUser.role) {
      const key = [UserRole.CLINICIAN, UserRole.ORGANIZATION, UserRole.SITE].includes(currentUser.role)
        ? '/admin'
        : '/metrics';
      setOpenKeys([key]);
    }
  }, [currentUser.role]);

  const logoutPrompt = () => {
    Modal.confirm({
      title: 'Log out',
      icon: <LogoutOutlined />,
      content: 'Are you sure you want to log out?',
      okText: 'Yes',
      cancelText: 'Cancel',
      onOk: () => {
        navigate('/login');
        capturePostHogCustomEvent('LogoutPrompt logged out');
        dispatch(logout());
      },
      onCancel: () => {
        capturePostHogCustomEvent('LogoutPrompt close modal');
      },
    });
  };

  const onOpenChange: MenuProps['onOpenChange'] = (openKeys) => {
    const [, lastOpen] = openKeys;
    setOpenKeys([lastOpen]);
  };

  const DefaultMenu = (
    <Menu
      style={{ marginTop: 15, borderRight: 0 }}
      selectedKeys={[path]}
      theme="light"
      mode="inline"
      openKeys={openKeys}
      onOpenChange={onOpenChange}
      items={getMenuItems(currentUser, authResult, missedScheduledProductCount)}
      onSelect={({ key }) => {
        if (key === '/logout') {
          logoutPrompt();
        } else {
          navigate(`/page${key}`);
        }
      }}
    />
  );

  const AdminMenu = (
    <Menu
      style={{ marginTop: 15, borderRight: 0 }}
      selectedKeys={[path]}
      theme="light"
      mode="inline"
      openKeys={openKeys}
      onOpenChange={onOpenChange}
      items={getAdminMenuItems()}
      onSelect={({ key }) => {
        if (key === '/logout') {
          logoutPrompt();
        } else {
          navigate(`/page${key}`);
        }
      }}
    />
  );

  const ProgramAdministationMenu = (
    <Menu
      style={{ marginTop: 15, borderRight: 0 }}
      selectedKeys={[path]}
      theme="light"
      mode="inline"
      openKeys={openKeys}
      onOpenChange={onOpenChange}
      items={getProgramAdministrationMenuItems(currentUser)}
      onSelect={({ key }) => {
        if (key === '/logout') {
          logoutPrompt();
        } else {
          navigate(`/page${key}`);
        }
      }}
    />
  );

  return (
    <>
      {currentUser.role === UserRole.CLINICIAN ||
      currentUser.role === UserRole.ORGANIZATION ||
      currentUser.role === UserRole.SITE
        ? DefaultMenu
        : null}
      {currentUser.role === UserRole.ADMIN ? AdminMenu : null}
      {currentUser.role === UserRole.PROGRAM_ADMINISTRATION ? ProgramAdministationMenu : null}
    </>
  );
};
