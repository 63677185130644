import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { SelectedKeys } from '../models/table';

type TableSearchSliceType = {
  searchText: string;
  searchedColumn: string;
};

const initialState: TableSearchSliceType = {
  searchText: '',
  searchedColumn: '',
};

export const tableSearchSlice = createSlice({
  name: 'tableSearch',
  initialState,
  reducers: {
    handleResetSearch: () => initialState,
    handleSearchSlice: (state, action: PayloadAction<{ selectedKeys: SelectedKeys; dataIndex: string }>) => {
      state.searchText = action.payload.selectedKeys[0] as string;
      state.searchedColumn = action.payload.dataIndex;
    },
  },
});

export const { handleResetSearch, handleSearchSlice } = tableSearchSlice.actions;
