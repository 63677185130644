import type { TablePaginationConfig } from 'antd/lib/table/interface';
import type { AxiosError } from 'axios';
import axios from 'axios';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { APIStatus } from '../../../enum';
import type { ErrorMessage } from '../../../models/error';
import type { AsyncThunkConfig } from '../../../models/slice';
import type { FilteredInfo, TableChange } from '../../../models/table';
import { SortedInfo } from '../../../models/table';
import { removeEmpty } from '../../../service/table.service';

export type WeeklyCompletion = {
  completion_id: string;
  client_id: string;
  name: string;
  site: string;
  enrollment_date: string;
  week1_survey_count: number;
  week2_survey_count: number;
  week3_survey_count: number;
  week4_survey_count: number;
  total_count: number;
};

type RequestParams = {
  pagination: TablePaginationConfig;
  sortedInfo: SortedInfo<WeeklyCompletion>;
  filteredInfo: FilteredInfo;
};

type WeeklyCompletionSliceType = {
  weeklyCompletionData: WeeklyCompletion[];
  requestParams: RequestParams;
  apiStatus: APIStatus;
  apiErrorText: string;
};

const initialState: WeeklyCompletionSliceType = {
  weeklyCompletionData: [],
  apiErrorText: '',
  apiStatus: APIStatus.IDLE,
  requestParams: {
    pagination: {
      current: 1,
      pageSize: 50,
      defaultPageSize: 50,
    },
    sortedInfo: new SortedInfo<WeeklyCompletion>('name', 'ascend'),
    filteredInfo: {},
  },
};

export const fetchWeeklyCompletion = createAsyncThunk<WeeklyCompletion[], undefined, AsyncThunkConfig>(
  'weeklyCompletionYale/fetchWeeklyCompletion',
  async (_, thunkAPI) => {
    try {
      const {
        weeklyCompletionYaleSlice: { requestParams },
      } = thunkAPI.getState();
      const response = await axios.post('v3_weekly_completion_rates', requestParams);
      return (response as unknown as WeeklyCompletion[]) ?? [];
    } catch (e) {
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const weeklyCompletionYaleSlice = createSlice({
  name: 'weeklyCompletionYale',
  initialState,
  reducers: {
    handleTableChanges: (state, action: PayloadAction<TableChange<WeeklyCompletion>>) => {
      const filters = action.payload.filters as FilteredInfo;
      const sorter = action.payload.sorter as SortedInfo<WeeklyCompletion>;
      if (!sorter.order) {
        sorter.order = null;
      }
      state.requestParams.pagination = action.payload.pagination;
      state.requestParams.sortedInfo = sorter;
      state.requestParams.filteredInfo = removeEmpty(filters);
    },
    clearFilter: (state) => {
      state.requestParams.pagination = initialState.requestParams.pagination;
      state.requestParams.sortedInfo = initialState.requestParams.sortedInfo;
      state.requestParams.filteredInfo = initialState.requestParams.filteredInfo;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWeeklyCompletion.pending, (state, _action) => {
        state.apiStatus = APIStatus.PENDING;
      })
      .addCase(fetchWeeklyCompletion.fulfilled, (state, action) => {
        state.weeklyCompletionData = action.payload;
        state.apiStatus = APIStatus.FULFILLED;
      })
      .addCase(fetchWeeklyCompletion.rejected, (state, _action) => {
        state.apiStatus = APIStatus.ERROR;
      });
  },
});

export const { handleTableChanges, clearFilter } = weeklyCompletionYaleSlice.actions;
