import React from 'react';
import { ConfigProvider } from 'antd';
import enUS from 'antd/locale/en_US';
import type { PostHogConfig } from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import './App.scss';

import { App } from './App';
import { AuthPersist } from './components/pages/Login/auth-persist';
import { environment } from './environment/environment';
import store from './store';

// https://posthog.com/docs/integrate/client/js#config
const options: Partial<PostHogConfig> = {
  api_host: 'https://app.posthog.com',
  property_denylist: ['email', 'password', 'pass', 'new_password', 'old_password'],
};

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);

  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <PostHogProvider apiKey={environment.parrotPosthogToken} options={options}>
          <AuthPersist>
            <ConfigProvider locale={enUS}>
              <App />
            </ConfigProvider>
          </AuthPersist>
        </PostHogProvider>
      </Provider>
    </React.StrictMode>,
  );
}
