import { notification } from 'antd';
import type { AxiosError } from 'axios';
import axios from 'axios';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import type { ErrorMessage } from '../../../models/error';
import type { AsyncThunkConfig } from '../../../models/slice';
import { RaygunErrorHandlerService } from '../../../service/raygun.service';
import type { Client } from '../../pages/client-upsert/clientUpsertSlice';

const { logError } = RaygunErrorHandlerService();

type SetPasswordResetClient = Pick<Client, 'id' | 'first_name' | 'last_name' | 'has_submission'>;

type ResetClientPasswordSliceType = {
  showModal: boolean;
  client: SetPasswordResetClient;
  loading: boolean;
};

const initialState: ResetClientPasswordSliceType = {
  showModal: false,
  client: { id: null, first_name: '', last_name: '', has_submission: false },
  loading: false,
};

export type ResetClientPasswordParams = {
  client_id: string;
  password: string;
  has_submission: boolean;
};

export const adminClientPasswordReset = createAsyncThunk<boolean, ResetClientPasswordParams, AsyncThunkConfig>(
  'clientSetTempPassword/adminClientPasswordReset',
  async (resetPassword, thunkAPI) => {
    try {
      if (resetPassword.password.length < 5) {
        notification.error({
          message: 'Temporary password too short',
          description: 'The temporary password must be at least 5 characters long.',
        });
      } else {
        const response = (await axios.post('v2_admin_client_password_reset', resetPassword)) as boolean;
        if (response) {
          notification.destroy();
          notification.success({
            message: 'Success',
            description: 'Temporary password set successfully.',
          });
          thunkAPI.dispatch(closeSetPasswordModal());
        } else {
          notification.error({
            message: 'Error',
            description: 'Temporary password not updated.',
          });
        }
        return response;
      }
      return false;
    } catch (e) {
      logError(e, ['clientSetTempPasswordSlice', 'adminClientPasswordReset']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const clientSetTempPasswordSlice = createSlice({
  name: 'clientSetTempPassword',
  initialState,
  reducers: {
    showSetPasswordModal: (state, action: PayloadAction<SetPasswordResetClient>) => {
      state.client = action.payload;
      state.showModal = true;
    },
    closeSetPasswordModal: (state) => {
      state.client = initialState.client;
      state.showModal = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(adminClientPasswordReset.pending, (state, _action) => {
        state.loading = true;
      })
      .addCase(adminClientPasswordReset.rejected, (state, _action) => {
        state.loading = false;
      })
      .addCase(adminClientPasswordReset.fulfilled, (state, _action) => {
        state.loading = false;
      });
  },
});

export const { showSetPasswordModal, closeSetPasswordModal } = clientSetTempPasswordSlice.actions;
