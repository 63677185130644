import type { MenuProps } from 'antd';
import { Badge } from 'antd';
import type { SubMenuType } from 'antd/es/menu/interface';

import {
  BarChartOutlined,
  BulbOutlined,
  CalendarOutlined,
  HomeOutlined,
  LogoutOutlined,
  ProductOutlined,
  SettingOutlined,
  UserAddOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from '@ant-design/icons';

import { UserOrganization, UserRole } from '../../enum';
import { isMobile } from '../../store';
import type { AuthResult, CurrentUser } from '../pages/Login/authSlice';

const AdminSubMenuChildren = [
  { label: 'Clients', key: '/admin-clients' },
  { label: 'Clinicians', key: '/admin-clinicians' },
  { label: 'Sites', key: '/admin-sites' },
];

const MetricsSubMenuChildren = [
  { label: 'Program dashboard', key: '/program-dashboard' },
  { label: 'Clinician dashboard', key: '/clinician-dashboard' },
  { label: 'Reports', key: '/report' },
  { label: 'Weekly completion rates', key: '/weekly-completion-yale' },
  { label: 'Interventions', key: '/interventions' },
  { label: 'Inactive clients', key: '/inactive-clients' },
  { label: 'Validation', key: '/validation' },
];

export const ProgramAdminMetricsSubMenuChildren = [
  { label: 'Program dashboard', key: '/program-dashboard' },
  { label: 'Reports', key: '/report' },
];

export const getItems = (missedScheduledProductCount: number) => {
  const ScheduledBadge = (
    <>
      Scheduled Products{' '}
      {missedScheduledProductCount > 0 && (
        <Badge
          count={missedScheduledProductCount}
          overflowCount={50}
          style={{ backgroundColor: '#f3a536' }}
          title="Missed scheduled products count"
        />
      )}
    </>
  );

  const items: MenuProps['items'] = [
    {
      label: 'Home',
      icon: <HomeOutlined />,
      key: '/home',
    },
    {
      label: 'Clients',
      icon: <UsergroupAddOutlined />,
      key: '/clients',
    },
    {
      label: ScheduledBadge,
      icon: <CalendarOutlined />,
      key: '/scheduled-products',
    },
    {
      label: 'Admin',
      icon: <UserOutlined />,
      key: '/admin',
    },
    {
      label: 'Metrics',
      icon: <BarChartOutlined />,
      key: '/metrics',
    },
    {
      label: 'Add client',
      icon: <UserAddOutlined />,
      key: '/client-onboarding',
    },
    {
      label: 'Resources',
      icon: <BulbOutlined />,
      key: '/resources',
    },
    {
      label: ' My settings',
      icon: <SettingOutlined />,
      key: '/clinician-settings',
    },
    {
      label: 'Log out',
      icon: <LogoutOutlined />,
      key: '/logout',
    },
  ];

  return items;
};

const adminItems: MenuProps['items'] = [
  {
    label: 'Metrics',
    icon: <BarChartOutlined />,
    key: '/metrics',
    children: [
      { label: 'Program dashboard', key: '/program-dashboard' },
      { label: 'Reports', key: '/report' },
      { label: 'Weekly completion rates', key: '/weekly-completion-yale' },
      { label: 'Interventions', key: '/interventions' },
      { label: 'Inactive clients', key: '/inactive-clients' },
      { label: 'Validation', key: '/validation' },
    ],
  },
  {
    label: 'Admin',
    icon: <UserOutlined />,
    key: '/admin',
    children: [
      { label: 'Clients', key: '/admin-clients' },
      { label: 'Clinicians', key: '/admin-clinicians' },
      { label: 'Organizations', key: '/admin-organizations' },
      { label: 'Products', key: '/admin-products' },
      { label: 'Sites', key: '/admin-sites' },
    ],
  },
  {
    label: 'Add client',
    icon: <UserAddOutlined />,
    key: '/client-onboarding',
  },
  {
    label: 'Resources',
    icon: <BulbOutlined />,
    key: '/resources',
  },
  {
    label: 'HHC weekend schedule',
    icon: <UserAddOutlined />,
    key: '/hhc-weekend-schedule',
  },
  {
    label: 'Add product',
    icon: <ProductOutlined />,
    key: '/admin-add-product',
  },
  {
    label: 'Log out',
    icon: <BulbOutlined />,
    key: '/logout',
  },
];

const programAdminItems: MenuProps['items'] = [
  {
    label: 'Metrics',
    icon: <BarChartOutlined />,
    key: '/metrics',
    children: [
      { label: 'Program dashboard', key: '/program-dashboard' },
      { label: 'Reports', key: '/report' },
    ],
  },
  {
    label: 'Admin',
    icon: <UserOutlined />,
    key: '/admin',
    children: [{ label: 'Clinicians', key: '/admin-clinicians' }],
  },
  {
    label: 'Add client',
    icon: <UserAddOutlined />,
    key: '/client-onboarding',
  },
  {
    label: 'Resources',
    icon: <BulbOutlined />,
    key: '/resources',
  },
  {
    label: 'HHC weekend schedule',
    icon: <UserAddOutlined />,
    key: '/hhc-weekend-schedule',
  },
  {
    label: 'Log out',
    icon: <BulbOutlined />,
    key: '/logout',
  },
];

export const getMenuItems = (currentUser: CurrentUser, authResult: AuthResult, missedScheduledProductCount: number) => {
  const items = getItems(missedScheduledProductCount);
  return items.filter((item) => {
    switch (item?.key) {
      case '/home':
        return true;
      case '/clients':
        return currentUser.role === UserRole.CLINICIAN;
      case '/scheduled-products':
        return authResult.use_scheduler;
      case '/admin':
        (item as SubMenuType).children = AdminSubMenuChildren.filter((c) => {
          switch (c.key) {
            case '/admin-sites':
              return currentUser.organization_id !== UserOrganization.MKI && currentUser.role === UserRole.ORGANIZATION;
            default:
              return true;
          }
        });
        return (currentUser.role === UserRole.ORGANIZATION || currentUser.role === UserRole.SITE) && !isMobile;
      case '/metrics':
        if (item) {
          (item as SubMenuType).children = MetricsSubMenuChildren.filter((c) => {
            switch (c.key) {
              case '/program-dashboard':
                return currentUser.organization_id !== UserOrganization.YALE;
              case '/clinician-dashboard':
                return authResult.use_clinician_dashboard;
              case '/weekly-completion-yale':
                return currentUser.role === UserRole.ADMIN || currentUser.organization_id === UserOrganization.YALE;
              case '/interventions':
                return authResult.use_actions;
              default:
                return true;
            }
          });
        }

        return (
          currentUser.role === UserRole.ORGANIZATION &&
          currentUser.organization_id !== UserOrganization.MKI &&
          !isMobile
        );
      case '/program-dashboard':
        return currentUser.role === UserRole.SITE && !isMobile;
      case '/client-onboarding':
      case '/resources':
      case '/clinician-settings':
      case '/logout':
        return true;
      default:
        return true;
    }
  });
};

export const getAdminMenuItems = () => {
  return adminItems.filter((item) => {
    switch (item?.key) {
      case '/admin':
      case '/metrics':
        return !isMobile;
      default:
        return true;
    }
  });
};

export const getProgramAdministrationMenuItems = (currentUser: CurrentUser) => {
  return programAdminItems.filter((item) => {
    switch (item?.key) {
      case '/metrics':
        (item as SubMenuType).children = ProgramAdminMetricsSubMenuChildren.filter((c) => {
          switch (c.key) {
            case '/program-dashboard':
              return currentUser.organization_id !== UserOrganization.YALE;
            default:
              return true;
          }
        });
        return !isMobile;
      case '/admin':
        return !isMobile;
      case '/client-onboarding':
      case '/resources':
      case '/clinician-settings':
      case '/logout':
        return true;
      default:
        return true;
    }
  });
};
